import { useTranslation } from 'react-i18next';

export const Feedback = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const desktopImageSrc =
    currentLanguage === 'ru'
      ? 'assets/feedback-desktop.png'
      : 'assets/feedback-desktop-eng.png';

  const mobileImageSrc =
    currentLanguage === 'ru'
      ? 'assets/feedback-mobile.png'
      : 'assets/feedback-mobile-eng.png';

  return (
    <section className='feedback'>
      <div className='wrapper'>
        <div className='feedback__body'>
          <p
            className='feedback__desc'
            dangerouslySetInnerHTML={{ __html: t('landing.feedback.title') }}
          />
          <a
            href='https://t.me/TgBoards_bot'
            target='_blank'
            className='button button--big'
          >
            {t('landing.button.getApp')}
          </a>

          <picture>
            <source media='(max-width: 767px)' srcSet={mobileImageSrc} />
            <img src={desktopImageSrc} alt='Изображение для десктопа' />
          </picture>
        </div>
      </div>
    </section>
  );
};
