import { verticalListSortingStrategy } from '@dnd-kit/sortable';
import { areEqualWithIndexPath } from '@shared/lib/dnd';
import { Sortable } from '@shared/ui/sortable';
import {
  SortableDroppableList,
  SortableListOverlay,
} from '@shared/ui/sortable/SortableList';
import { SortableCard } from '@widgets/card/components/Card';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { Desk, DeskProps } from '../types';
import { DeskHeader } from './DeskHeader';
import classNames from 'classnames';
import { darkTheme, useTelegram } from '@hooks/useTelegram';
import useScreenSize from '@hooks/getScreenSize';

export const DeskContent = React.memo(
  observer(({ desk, isOverlay, parentId, indexPath }: DeskProps) => {
    const { sm } = useScreenSize();
    const ListElement = isOverlay ? SortableListOverlay : SortableDroppableList;

    const isTg = useTelegram();

    const filteredTasks = useMemo(() => {
      return desk.children.filter((task) => task.data.desk_id === desk.id);
    }, [desk]);

    const ctx = {
      parentId,
      indexPath,
      data: desk,
    };

    const childCount = desk.data.child?.length;

    return (
      <section
        className={classNames(
          `${darkTheme ? 'bg-black' : 'bg-boardBackground'} flex h-fit max-h-[calc(100svh_-_100px)] touch-none select-none flex-col rounded-normal p-[12px_8px_0]`,
          { 'min-w-80 max-w-80': !isTg && !desk.data.parent_id },
          {
            'min-w-[80vw] max-w-[80vw] sm:min-w-80 sm:max-w-80':
              isTg && !desk.data.parent_id,
          },
          {
            'min-w-[640px] max-w-[640px]': !isTg && desk.data.child?.length > 1,
          },
          {
            'min-w-[calc(320px_-_16px)] max-w-[calc(320px_-_116px)] !p-0':
              !isTg && desk.data.parent_id,
          },
          {
            'min-w-[calc(80vw_-_16px)] max-w-[calc(80vw_-_16px)] sm:min-w-[calc(320px_-_16px)] sm:max-w-[calc(320px_-_16px)] !p-0':
              isTg && desk.data.parent_id,
          },
        )}
        style={{
          maxWidth:
            ctx.data.type === 'desk' && childCount > 1
              ? !isTg || !sm
                ? 320 * childCount
                : `calc(80vw * ${childCount})`
              : undefined,
          minWidth:
            ctx.data.type === 'desk' && childCount > 1
              ? !isTg || !sm
                ? 320 * childCount
                : `calc(80vw * ${childCount})`
              : undefined,
        }}
      >
        {desk.data.name && <DeskHeader desk={desk.data} />}
        <ListElement
          accepts={'task'}
          content={desk}
          ctx={ctx}
          className={'overflow-auto'}
          direction={verticalListSortingStrategy}
        >
          {!desk.data.child?.length ? (
            <main className='flex max-h-full flex-1 flex-col gap-[10px] overflow-auto pb-[12px]'>
              {filteredTasks.map((task, i) => (
                <SortableCard
                  key={task.id}
                  isOverlay={!!isOverlay}
                  item={task}
                  itemIndex={i}
                  laneId={desk.id}
                  laneIndexPath={indexPath}
                />
              ))}
            </main>
          ) : (
            <main className='flex max-h-full flex-1 justify-between gap-[10px] overflow-auto'>
              {desk.data.child.map((d, i) => (
                <SortableDesk
                  key={d.id}
                  desk={{
                    id: d.id,
                    type: 'desk',
                    data: d,
                    children: desk.children,
                  }}
                  laneIndex={i}
                  parentId={desk.data.id}
                />
              ))}
            </main>
          )}
        </ListElement>
      </section>
    );
  }),
  areEqualWithIndexPath,
);

export const SortableDesk = React.memo(function SortableDesk({
  desk,
  parentId,
  laneIndex,
  className,
}: {
  desk: Desk;
  parentId: number | null;
  laneIndex: number;
  className?: string;
}) {
  const isBoardHasOneColumn = desk.data.child?.length === 1;

  const ctx = {
    parentId,
    indexPath: [laneIndex],
    data: isBoardHasOneColumn
      ? {
          id: desk.data.child![0].id,
          type: 'desk',
          data: desk.data.child![0],
          children: desk.data.child![0].tasks,
        }
      : desk,
  };

  return (
    <Sortable className={className} ctx={ctx}>
      <DeskContent parentId={parentId} indexPath={ctx.indexPath} desk={desk} />
    </Sortable>
  );
});
