import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { useLogic, useStore } from '@hooks/storeHook';
import { useTelegram } from '@hooks/useTelegram';
import { PlusIcon } from '@pages/icons/plusIcon';
import { Button, ButtonViews } from '@shared/ui/button';
import { NameModal } from '@shared/ui/nameModal';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const NewDeskButton = observer(() => {
  const isTg = useTelegram();
  const tg = window.Telegram.WebApp;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const { chatInfo } = useStore();
  const { t } = useTranslation();
  const logic = useLogic();

  const handleSubmit = (name: string) => {
    extraLightHapticFeedback();

    const existingDesk = chatInfo?.desks.find(
      (item) => item.name.toLowerCase() === name?.toLowerCase(),
    );
    if (name?.length === 0 || !name || existingDesk) {
      alert(t('alert.emptyBoardName'));
      tg.HapticFeedback.notificationOccurred('error');
      return;
    }

    logic.createBoard(name);
    extraLightHapticFeedback();
    handleCloseModal();
  };

  const onAddBoard = () => {
    handleOpenModal();
  };

  return (
    <>
      {isTg ? (
        <button
          onClick={onAddBoard}
          className={`flex cursor-pointer items-center justify-center border-none bg-green text-large font-bold text-white ${isTg ? 'min-w-[80vw] max-w-[80vw] sm:min-w-80 sm:max-w-80' : 'min-w-80 max-w-80'} h-[55px] rounded-normal`}
        >
          {t('addBoard')}
        </button>
      ) : (
        <Button
          view={ButtonViews.FILLED}
          icon={<PlusIcon />}
          onClick={onAddBoard}
          className='!pr-[10px]'
        >
          {t('board')}
        </Button>
      )}
      <NameModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
      />
    </>
  );
});
