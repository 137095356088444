import { useState } from 'react';
import { NameModalProps } from './types';
import { darkTheme, useTelegram } from '@hooks/useTelegram';
import { Input } from '../input';
import { useTranslation } from 'react-i18next';

export const NameModal = ({ isOpen, onClose, onSubmit }: NameModalProps) => {
  const [name, setName] = useState('');
  const { t } = useTranslation();

  const isTg = useTelegram()

  window.Telegram.Web

  const handleSubmit = () => {
    if (name.trim()) {
      onSubmit(name);
      setName('');
    } else {
      alert(t('alert.emptyBoardName'));
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  if (!isOpen) return null;

  return (
    <div className='fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-50'>
      <div
        className={`w-80 rounded-normal p-[12px] shadow-lg ${darkTheme ? 'bg-darkCardBackground' : 'bg-boardBackground'} ${isTg && '-mt-36'}`}
      >
        <h2
          className={`${darkTheme ? 'text-white' : 'text-black'} mb-6 text-large font-bold`}
        >
          {t('alert.enterBoardName')}
        </h2>
        <Input
          type='text'
          value={name}
          onKeyDown={handleKeyDown}
          onChange={(e) => setName(e.target.value)}
          className={`mb-6 w-full !rounded-[10px] border-none !p-[10px_14px] ${darkTheme && '!bg-boardBackground'}`}
          placeholder={t('input.titlePlaceholder')}
          autoFocus
        />
        <div className='flex justify-end space-x-2'>
          <button
            onClick={onClose}
            className='bg-transparent px-4 text-[15px] text-red'
          >
            {t('button.cancel')}
          </button>
          <button
            onClick={handleSubmit}
            className='bg-transparent px-4 text-[15px] text-blue'
          >
            {t('button.ok')}
          </button>
        </div>
      </div>
    </div>
  );
};
