import { ShareBotButton } from '@features/shareBotButton';
import { Menu } from '@widgets/projectMenu';

export const Page = () => {
  return (
    <section className='relative h-full w-full'>
      <Menu />
      <ShareBotButton />
    </section>
  );
};
