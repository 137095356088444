import { useStore } from '@hooks/storeHook';
import { useTelegram } from '@hooks/useTelegram';
import { CrossIcon } from '@pages/icons/crossIcon';
import { TaskPage } from '@pages/TaskPage/TaskPage';
import { Loader } from '@shared/ui/loader';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';

export interface ITaskModal {
  onHide: () => void;
  loading: boolean;
}

export const TaskModal: FC<ITaskModal> = observer(({ onHide, loading }) => {
  const { selectedTask } = useStore();
  const isTg = useTelegram();
  const { t } = useTranslation();
  const handlers = useSwipeable({
    onSwipedDown: () => onHide(),
    swipeDuration: 0,
    trackMouse: true,
  });

  const initial = {
    tg: {
      translateY: '100vh',
      borderRadius: '40px 40px 0 0',
    },
    desktop: {
      opacity: 0,
    },
  };

  const animate = {
    tg: {
      translateY: '0%',
      borderRadius: '0',
    },
    desktop: {
      opacity: 100,
    },
  };

  const exit = {
    tg: {
      translateY: '100%',
      borderRadius: '40px 40px 0 0',
    },
    desktop: {
      opacity: 0,
    },
  };
  return ReactDOM.createPortal(
    <motion.div
      className={`absolute inset-0 z-[9999] ${!isTg && 'p-8'}`}
      onClick={onHide}
      initial={{
        backgroundColor: 'rgba(0,0,0,0)',
      }}
      animate={{ backgroundColor: 'rgba(0,0,0,.70)' }}
      exit={{
        backgroundColor: 'rgba(0,0,0,0)',
      }}
      transition={{ duration: 0.2 }}
    >
      <motion.section
        className={`flex h-full w-full flex-col rounded-[20px_20px_0_0] bg-white ${!isTg && 'mx-auto max-w-[872px] overflow-hidden !rounded-[10px]'}`}
        onClick={(e) => e.stopPropagation()}
        initial={isTg ? initial.tg : initial.desktop}
        animate={isTg ? animate.tg : animate.desktop}
        exit={isTg ? exit.tg : exit.desktop}
        transition={{ duration: 0.15 }}
        {...handlers}
      >
        {loading ? (
          <Loader fullscreen={false} blur={false} />
        ) : (
          <>
            {!isTg && (
              <div className='flex min-h-[58px] items-center justify-between bg-blue px-[18px]'>
                <p className='text-[22px] text-white'>
                  {selectedTask?.id ? t('editTask') : t('newTask')}
                </p>
                <button
                  className='m-0 h-fit w-fit cursor-pointer border-none bg-transparent p-0'
                  onClick={onHide}
                >
                  <CrossIcon />
                </button>
              </div>
            )}
            <TaskPage onHide={onHide} />
          </>
        )}
      </motion.section>
    </motion.div>,
    document.getElementById('root')!,
  );
});
