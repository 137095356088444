import { ITask } from '@@types/types';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { useLogic } from '@hooks/storeHook';
import { CheckBox } from '@pages/icons/checkBox';
import { EnterIcon } from '@pages/icons/enterIcon';
import { Spinner } from '@shared/ui/spinner';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import { SubTasks } from './SubTasks';

export interface ITaskAndSubtasksComponent {
  setTask: (field: string, value: ITask[keyof ITask]) => void;
  task: ITask;
}

export const TaskAndSubtasksComponent = observer(
  ({ setTask, task }: ITaskAndSubtasksComponent) => {
    const subtasks = task?.subtasks;
    const [isLoading, setIsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const logic = useLogic();
    const { t } = useTranslation();

    const subTaskTextRef = useRef<any>();

    const [subTaskTitleInput, setInput] = useState('');

    const onChangeTitle = (e: any) => {
      setTask('title', e.target.value);
    };

    const handleSubTaskTitleInput = (event: any) => {
      setInput(event.currentTarget.value);
    };

    async function handleCreateSubTask() {
      if (subTaskTitleInput.length === 0) return;

      setIsLoading(true);
      if (task.id)
        await logic.updateTask({
          ...task,
          subtasks: [
            ...task.subtasks,
            {
              id: task.subtasks.length,
              task_id: 0,
              title: subTaskTitleInput,
              status: 'active',
              created_at: '',
              updated_at: '',
            },
          ],
        });
      setIsLoading(false);

      setTask('subtasks', [
        ...task.subtasks,
        {
          id: task.subtasks.length,
          task_id: 0,
          title: subTaskTitleInput,
          status: isChecked ? 'complete' : 'active',
          created_at: '',
          updated_at: '',
        },
      ]);
      extraLightHapticFeedback();

      setInput('');
    }

    const handleKeyDown = async (
      e: React.KeyboardEvent<HTMLTextAreaElement>,
    ) => {
      if (e.key === 'Enter' && e.shiftKey) {
        e.preventDefault();
        const cursorPosition = subTaskTextRef.current?.selectionStart || 0;
        const newText =
          subTaskTitleInput.slice(0, cursorPosition) +
          '\n' +
          subTaskTitleInput.slice(cursorPosition);
        setInput(newText);

        setTimeout(() => {
          if (subTaskTextRef.current) {
            subTaskTextRef.current.setSelectionRange(
              cursorPosition + 1,
              cursorPosition + 1,
            );
            subTaskTextRef.current.focus();
          }
        }, 0);
      } else if (e.key === 'Enter') {
        e.preventDefault();
        await handleCreateSubTask();
        setTimeout(() => {
          subTaskTextRef.current?.focus();
        }, 0);
      }
    };

    return (
      <section className='flex flex-col gap-[19px] pb-[14px] pt-[17px]'>
        <TextareaAutosize
          value={task?.title ?? ''}
          onChange={onChangeTitle}
          minRows={1}
          placeholder={t('input.taskPlaceholder')}
          className='w-full resize-none !border-none bg-transparent p-0 text-large -tracking-[0.4] text-black shadow-none focus:outline-none'
        />
        <div className='flex flex-col gap-[15px]'>
          {subtasks?.map((subtask, index) => (
            <SubTasks
              subtask={subtask}
              index={index}
              key={subtask.id}
              setTask={setTask}
              task={task}
            />
          ))}
          <div className='flex w-full items-center'>
            <div
              className='min-w-[20px] cursor-pointer'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsChecked(!isChecked);
              }}
            >
              <CheckBox active={isChecked} />
            </div>
            <div className='ml-[10px] flex w-full items-center'>
              <div className='mr-[10px] flex w-full items-center gap-[5px] border-0 border-b-[1px] border-solid border-border-grey'>
                <TextareaAutosize
                  value={subTaskTitleInput}
                  onChange={handleSubTaskTitleInput}
                  ref={subTaskTextRef}
                  onKeyDown={handleKeyDown}
                  disabled={isLoading}
                  minRows={1}
                  maxRows={6}
                  className='w-full resize-none !border-none bg-transparent !p-0 !pb-[2px] text-[15px] tracking-[0] shadow-none focus:outline-none'
                  placeholder={t('input.subtaskPlaceholder')}
                />
              </div>
            </div>
            <motion.div
              transition={{ duration: 0.7 }}
              animate={{ rotateY: !isLoading ? 0 : 180 }}
              className='ml-auto h-[20px] min-w-[20px]'
              style={{ scale: '(-1, 1)' }}
            >
              <motion.div
                animate={{
                  rotateY: !isLoading ? 0 : 180,
                }}
                onClick={handleCreateSubTask}
                className='l-0 t-0 absolute ml-auto h-[20px] cursor-pointer'
                style={{ backfaceVisibility: 'hidden' }}
              >
                <EnterIcon />
              </motion.div>
              <motion.div
                initial={{ rotateY: 180 }}
                animate={{
                  rotateY: !isLoading ? 180 : 0,
                }}
                className='l-0 t-0 absolute ml-auto h-[20px] cursor-pointer'
                style={{ backfaceVisibility: 'hidden' }}
              >
                <Spinner width={20} height={20} thickness={2} />
              </motion.div>
            </motion.div>
          </div>
        </div>
      </section>
    );
  },
);
