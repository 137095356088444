import { SettingsElemProps } from '../types';
import { Icon } from './Icon';

export const SettingsElem = ({
  title,
  onClick,
  suptitle,
  showArrow,
  icon,
}: SettingsElemProps) => {
  return (
    <>
      <li
        onClick={onClick}
        className='flex cursor-pointer items-center px-4 py-3'
      >
        {icon && <div className='mr-4 h-7 w-7 flex justify-center items-center'>{icon}</div>}
        <div className='flex flex-col gap-[2px]'>
          <p className='text-large'>{title}</p>
          {suptitle && (
            <p className='text-bigger leading-[16px] text-[var(--tg-theme-subtitle-text-color)]'>
              {suptitle}
            </p>
          )}
        </div>
        {showArrow && (
          <div className='ml-auto text-[var(--tg-theme-subtitle-text-color)]'>
            <Icon />
          </div>
        )}
      </li>
    </>
  );
};
