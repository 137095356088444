import { useLogic, useStore } from '@hooks/storeHook';
import { darkTheme } from '@hooks/useTelegram';
import { CardContent } from '@widgets/card';
import clx from 'classnames';
import { TaskTaskProps } from '../types';
import { useState } from 'react';

export const TaskCard = ({ task }: TaskTaskProps) => {
  const { allChats } = useStore();
  const logic = useLogic();
  const [isHovered, setIsHovered] = useState(false);

  const fetchTask = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    await logic.getTask(task.chat_id, task.id!);
    e.preventDefault();
  };

  return (
    <div
      className={clx(
        `w-full cursor-pointer justify-between gap-[15px] px-[14px] pb-[9px] pt-[7px] text-[15px] leading-[22px] transition-colors duration-300`,
        { 'bg-[#191919]': darkTheme && !isHovered },
        { 'bg-white': !darkTheme && !isHovered },
        { '!bg-darkCardBackground': darkTheme && isHovered },
        { '!bg-greyHover': !darkTheme && isHovered },
      )}
      onClick={fetchTask}
      onTouchStart={() => setIsHovered(true)}
      onTouchEnd={() => setIsHovered(false)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CardContent task={task} />
      <div
        className={`mt-[4px] w-fit rounded-[4px] ${darkTheme ? 'bg-[#4f4f4f] text-white' : 'bg-greySecondary text-greyText'} px-[24px] py-[2px] text-[14px] tracking-[0] text-greyText`}
      >
        {allChats.find((chat) => task.chat_id === chat.id)?.title}
      </div>
    </div>
  );
};
