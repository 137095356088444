import { PropsWithChildren } from 'react';

export const SettingSection = ({
  name,
  children,
}: PropsWithChildren & { name: string }) => (
  <div className='flex w-full flex-col gap-[8px]'>
    <span className='pl-[15px] text-bigger uppercase text-grey-text'>
      {name}
    </span>
    <div className='w-full rounded-[12px] bg-white px-[18px]'>{children}</div>
  </div>
);
