import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { ILoader } from './types';
import clx from 'classnames';

export const Loader = observer(({ blur, fullscreen }: ILoader) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className={clx(
        'pointer-events-none z-[9999999] flex items-center justify-center backdrop-blur-md',
        { 'backdrop-blur-md': blur },
        { 'absolute inset-0': fullscreen },
        { 'h-full w-full': !fullscreen },
      )}
    >
      <div className='flex h-[100px] w-[100px] scale-[50%] justify-between gap-[7px] rounded-[21px] bg-white bg-icon-gradient px-[21px] py-[21px] drop-shadow-[1.4_0.14px_0.56px_rgb(0_0_0_/_0.2)] sm:h-[140px] sm:w-[140px]'>
        <motion.div
          className='w-full rounded-[2.1px] bg-white drop-shadow-[5.3_0.28px_0.28px_rgb(0_0_0_/_0.15)]'
          animate={{
            height: ['23%', '100%', '23%'],
          }}
          transition={{ repeat: Infinity, duration: 3 }}
        />
        <motion.div
          className='w-full rounded-[2.1px] bg-white drop-shadow-[5.3_0.28px_0.28px_rgb(0_0_0_/_0.15)]'
          animate={{ height: ['5%', '55.6%', '5%'] }}
          transition={{ repeat: Infinity, duration: 1 }}
        />
        <motion.div
          className='w-full rounded-[2.1px] bg-white drop-shadow-[5.3_0.28px_0.28px_rgb(0_0_0_/_0.15)]'
          animate={{
            height: ['67.7%', '14%', '67.7%'],
          }}
          transition={{ repeat: Infinity, duration: 1 }}
        />
      </div>
    </motion.div>
  );
});
