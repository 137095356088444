import { IChat, ITask, IUser } from '@@types/types';

export enum viewsTypes {
  PROJECTS = 'projects',
  CONTACTS = 'contacts',
  TASKS = 'myTasks',
}

export interface ProjectCardProps {
  chat: IChat;
}

export interface MenuElemProps {
  viewType: viewsTypes;
  active: boolean;
  onChangeView: (v: viewsTypes) => void;
}

export interface TaskTaskProps {
  task: ITask;
}

export interface ContactCardProps {
  contact: IUser;
}
