import TaskInfo from '@entities/task';
import { TaskDatePicker } from '@features/taskDatePicker';
import { darkTheme } from '@hooks/useTelegram';
import { areEqualWithIndexPath } from '@shared/lib/dnd';
import { isChromeOrSafari } from '@shared/lib/isChromeOrSafari';
import { Sortable, SortableItemOverlay } from '@shared/ui/sortable/Sortable';
import React, { useEffect, useRef, useState } from 'react';
import { CardProps, CardType } from '../types';
import { useTaskModalContext } from '@pages/main/lib/context';

export const CardContent = React.memo(({ task }: CardProps) => {
  const [isInline, setIsInline] = useState(true);
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        if (isChromeOrSafari())
          setIsInline(parentRef.current.clientHeight <= 209);
        else setIsInline(parentRef.current.clientHeight <= 167);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [parentRef.current]);

  return (
    <div className={`w-full`}>
      <span
        ref={parentRef}
        className={`${darkTheme ? 'text-white' : 'text-black'} card-text line-clamp-[10] hidden select-none text-ellipsis align-middle text-[15px] leading-[21px]`}
        style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical' }}
      >
        <TaskInfo task={task} isInline={isInline} />
        <span
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <TaskDatePicker task={task} />
        </span>
      </span>
    </div>
  );
});

export const SortableCard = React.memo(function SortableCard({
  isOverlay,
  item,
  itemIndex,
  laneId,
  laneIndexPath,
}: {
  isOverlay: boolean;
  item: CardType;
  itemIndex: number;
  laneId: number;
  laneIndexPath: number[];
}) {
  const ItemElement = isOverlay ? SortableItemOverlay : Sortable;
  const taskModalCtx = useTaskModalContext();

  const onClickHandler = () => {
    taskModalCtx.getTask(item.data.chat_id, item.data.id!);
  };

  const ctx = {
    parentId: laneId,
    indexPath: [...laneIndexPath, itemIndex],
    data: item,
  };

  return (
    <div onClick={isOverlay ? undefined : onClickHandler}>
      <ItemElement
        ctx={ctx}
        className={`w-full cursor-pointer justify-between gap-[15px] rounded-[12px] px-[14px] pb-[9px] pt-[7px] text-[15px] leading-[22px] ${darkTheme ? 'bg-darkCardBackground' : 'bg-white'}`}
        needAnimate={false}
      >
        <CardContent task={item.data} />
      </ItemElement>
    </div>
  );
}, areEqualWithIndexPath);
