import { useEffect, useLayoutEffect } from 'react';
import '../main.min.css';
import { Advantages } from './Advantages';
import { Feedback } from './Feedback';
import { Footer } from './Footer';
import { Header } from './Header';
import { Preview } from './Preview';
import { useTranslation } from 'react-i18next';
import { russianSpeakingCountries } from '@shared/const/russianSpeakingCountries';

export const Page = () => {
  const { i18n } = useTranslation();

  useLayoutEffect(() => {
    i18n.changeLanguage('en');
  }, []);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await fetch('https://api.country.is/');
        const data = await response.json();

        if (russianSpeakingCountries.includes(data.country)) {
          i18n.changeLanguage('ru');
        } else {
          i18n.changeLanguage('en');
        }
      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };

    fetchLocation();
  }, []);

  return (
    <div className='h-full w-full bg-white'>
      <Header />
      <Preview />
      <Advantages />
      <Feedback />
      <Footer />
    </div>
  );
};
