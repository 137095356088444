import { useEffect } from 'react';
import clx from 'classnames';
import { useStore } from '@hooks/storeHook';
import { UserInfo } from '@widgets/userInfo';
import { useTranslation } from 'react-i18next';
import { SettingsList } from '@widgets/settingsList';
import { SettingsElem } from '@widgets/settingsElem';
import { SettingsSection } from '@widgets/settingsSection';
import { TFunction } from 'i18next';
import { AnimatePresence } from 'framer-motion';
import { SelectedIcon } from '@pages/icons/selectedIcon';
import { SettingSectionsEnum, useSettingsNavigation } from '../lib/context';
import withNavigation from '../lib/withNavigation';

export const Page = withNavigation(() => {
  const tg = window.Telegram.WebApp;
  const { user } = useStore();
  const { t } = useTranslation();

  const { currentSection, goBack, navigateTo, stack } = useSettingsNavigation();

  useEffect(() => {
    tg.BackButton.show();
    tg.BackButton.onClick(goBack);

    return () => {
      tg.BackButton.hide();
      tg.BackButton.offClick(goBack);
    };
  }, [tg, stack]);

  if (!user) return <span>error</span>;

  const sectionSwitcher = (section: SettingSectionsEnum) => {
    switch (section) {
      case SettingSectionsEnum.MAIN:
        return (
          <MainSection
            onClick={() => navigateTo(SettingSectionsEnum.SETTINGS)}
            t={t}
          />
        );
      case SettingSectionsEnum.SETTINGS:
        return (
          <GeneralSettings
            onClick={() => navigateTo(SettingSectionsEnum.LANGUAGE)}
            t={t}
          />
        );
      case SettingSectionsEnum.LANGUAGE:
        return <LanguageSettings />;
      default:
        <MainSection
          onClick={() => navigateTo(SettingSectionsEnum.SETTINGS)}
          t={t}
        />;
    }
  };

  return (
    <main
      className={clx(`flex h-[100svh] w-full px-4 pt-4`)}
      style={{
        backgroundColor: 'var(--tg-theme-secondary-bg-color)',
        color: 'var(--tg-theme-text-color)',
      }}
    >
      <AnimatePresence mode='wait'>
        {sectionSwitcher(currentSection)}
      </AnimatePresence>
    </main>
  );
});

const MainSection = ({
  onClick,
  t,
}: {
  onClick: () => void;
  t: TFunction<'translation', undefined>;
}) => {
  const elems = [
    <SettingsElem onClick={onClick} title={t('settings')} showArrow />,
  ];

  return (
    <SettingsSection>
      <UserInfo />
      <div className='gap- mt-[15px] flex w-full flex-col gap-[15px]'>
        <SettingsList elems={elems} />
      </div>
    </SettingsSection>
  );
};

const GeneralSettings = ({
  onClick,
  t,
}: {
  onClick: () => void;
  t: TFunction<'translation', undefined>;
}) => {
  const elems = [
    <SettingsElem onClick={onClick} title={t('language')} showArrow />,
  ];

  return (
    <SettingsSection>
      <SettingsList elems={elems} />
    </SettingsSection>
  );
};

const LanguageSettings = () => {
  const { i18n } = useTranslation();

  const elems = [
    <SettingsElem
      onClick={() => i18n.changeLanguage('ru')}
      title={'Русский'}
      icon={i18n.language === 'ru' ? <SelectedIcon /> : <></>}
    />,
    <SettingsElem
      onClick={() => i18n.changeLanguage('en')}
      title={'English'}
      icon={i18n.language === 'en' ? <SelectedIcon /> : <></>}
    />,
  ];

  return (
    <SettingsSection>
      <SettingsList separatorType='short' elems={elems} />
    </SettingsSection>
  );
};
