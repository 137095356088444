import { NextIcon } from '@pages/icons/nextIcon';
import { PrevIcon } from '@pages/icons/prevIcon';
import { ChangeEvent, PropsWithChildren, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { RepeatOption, RepeatTypesEnum } from './types';
import { Button } from '@ui/Button/Button';
import { TimeInput } from '@shared/ui/timeInput';
import { Select } from '@shared/ui/select';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import moment from 'moment';
import { components, ControlProps, SingleValue } from 'react-select';
import { useTelegram } from '@hooks/useTelegram';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import classNames from 'classnames';
import { appColors } from '@config/theme';

const CustomControl = ({ children, ...props }: ControlProps<RepeatOption>) => {
  return (
    <components.Control {...props} className='!border-none !text-grey-text'>
      {children}
    </components.Control>
  );
};

export const CalendarComponent = ({
  defaultRepeatType,
  defaultValue,
  onSave,
}: PropsWithChildren & {
  defaultRepeatType: RepeatTypesEnum;
  defaultValue?: string | null;
  onSave: (
    date: Date | null,
    priority?: true,
    repeatType?: RepeatTypesEnum,
  ) => void;
}) => {
  const { t, i18n } = useTranslation();
  const pushOptions: RepeatOption[] = [
    {
      label: t('repeatType.never'),
      value: RepeatTypesEnum.NEVER,
    },
    {
      label: t('repeatType.everyyear'),
      value: RepeatTypesEnum.EVERY_YEAR,
    },
    {
      label: t('repeatType.everymonth'),
      value: RepeatTypesEnum.EVERY_MONTH,
    },
    {
      label: t('repeatType.everyweek'),
      value: RepeatTypesEnum.EVERY_WEEKDAY,
    },
    {
      label: t('repeatType.everyday'),
      value: RepeatTypesEnum.EVERYDAY,
    },
  ];

  const [date, setDate] = useState<Value>();
  const [time, setTime] = useState(
    defaultValue ? moment(defaultValue).format('HH:mm') : '19:00',
  );
  const [selectedOption, setSelectedOption] = useState<RepeatOption | null>(
    pushOptions.find((e) => e.value === defaultRepeatType) ??
      pushOptions.find((e) => e.value === RepeatTypesEnum.NEVER)!,
  );
  const currentLocale = i18n.language;
  const isTg = useTelegram();

  const onChangeTime = (e: ChangeEvent<HTMLInputElement>) => {
    setTime(e.target.value);
  };

  const onChangeRepatType = (option: SingleValue<RepeatOption>) => {
    setSelectedOption(option);
  };

  const onChangeDate = (
    value: Value,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    extraLightHapticFeedback();
    setDate(value);
  };

  const onSaveHandler = () => {
    const newDate = moment(date?.toString())
      .hours(+time.substring(0, 2))
      .minutes(+time.substring(3, 5));
    onSave(newDate.toDate(), undefined, selectedOption?.value);
  };

  return (
    <div className='flex w-full flex-col items-center justify-center'>
      <div className='flex h-fit min-h-[520px] w-[290px] flex-col rounded-[8px] bg-[white] pt-[10px]'>
        <Calendar
          defaultValue={defaultValue}
          onChange={(value, e) => onChangeDate(value, e)}
          prevLabel={<PrevIcon />}
          nextLabel={<NextIcon />}
          next2Label={null}
          prev2Label={null}
          locale={currentLocale}
          minDate={new Date()}
        />
        <div className='mt-4 flex w-full items-center justify-between px-2'>
          <label htmlFor='time'>
            <p>{t('time')}</p>
          </label>

          <TimeInput
            value={time}
            onChange={(e) => onChangeTime(e)}
            placeholder='00:00'
          />
        </div>
        <div className='mt-3 flex w-full items-center justify-between px-2'>
          <p>{t('repeat')}</p>
          <Select
            value={selectedOption}
            onChange={onChangeRepatType}
            isMulti={false}
            options={pushOptions}
            components={{
              Control: CustomControl,
              IndicatorsContainer: () => null,
            }}
            menuPlacement={isTg ? 'top' : 'bottom'}
            classNames={{
              control: () => 'border-none !border-[0px] !shadow-none',
              menu: () =>
                classNames('drop-shadow-none shadow-none !w-[200px]', {
                  'right-0': isTg,
                }),
            }}
            styles={{
              control: (provided) => ({
                ...provided,
                border: 'none',
                color: appColors['grey-text'],
                cursor: 'pointer',
                borderWidth: 0,
              }),
              singleValue: (provided) => ({
                ...provided,
                color: appColors['grey-text'],
                marginRight: 0,
                fontSize: 16,
              }),
            }}
          />
        </div>
        <Button classname='mt-[20px] sm:h-[50px]' onClick={onSaveHandler}>
          {t('button.save')}
        </Button>
      </div>
    </div>
  );
};
