import CloseIcon from '@assets/icons/closeRedIcon.svg';
import { motion } from 'framer-motion';
import { ReactElement, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

interface IMiniModal {
  parent: Element;
  direction: 'up' | 'down' | 'right' | 'left';
  headerTitle: string;
  body: ReactElement;
  onHide: () => void;
  classname?: string;
}

export const MiniModal = ({
  parent,
  direction,
  headerTitle,
  body,
  onHide,
  classname,
}: IMiniModal) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const [styles, setStyles] = useState<React.CSSProperties>({});

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        event.stopImmediatePropagation();
        event.preventDefault();
        onHide();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onHide]);

  useEffect(() => {
    const updatePosition = () => {
      if (popupRef.current) {
        const rect = parent.getBoundingClientRect();
        const popupWidth = popupRef.current.offsetWidth;
        const windowWidth = window.innerWidth;
        let newStyles: React.CSSProperties = {};

        switch (direction) {
          case 'up':
            newStyles = { top: rect.top };
            break;
          case 'down':
            newStyles = { top: rect.bottom };
            break;
          case 'right':
            newStyles = {
              left: rect.right + 20,
              top: rect.top,
            };
            if (rect.right + 20 + popupWidth > windowWidth) {
              newStyles.left = windowWidth - popupWidth - 20;
              newStyles.top = rect.top + rect.height;
            }
            break;
          case 'left':
            newStyles = {
              left: rect.left - popupWidth - 20,
              top: rect.top,
            };
            if (rect.left - popupWidth - 20 < 0) {
              newStyles.left = 20;
            }
            break;
        }

        setStyles(newStyles);
      }
    };

    updatePosition();
    window.addEventListener('resize', updatePosition);

    return () => {
      window.removeEventListener('resize', updatePosition);
    };
  }, [parent, direction]);

  return ReactDOM.createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className={`mini-modal-popup absolute z-[99999] flex flex-col rounded-[16px] bg-white drop-shadow-2xl ${classname}`}
      style={styles}
      ref={popupRef}
    >
      <header className="flex items-center justify-between p-[16px]">
        <span />
        <p className="text-large font-medium">{headerTitle}</p>
        <div className="cursor-pointer" onClick={onHide}>
          <img src={CloseIcon} width={24} height={24} />
        </div>
      </header>
      <main className="px-[18px] pb-[18px]">{body}</main>
    </motion.div>,
    document.querySelector('body')!,
  );
};
