import { LoadState } from '@@types/loadState';
import { ReactNode, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppRoutesPaths, setNavigate } from '@config/navigation';
import { useStore } from '@hooks/storeHook';
import { useTranslation } from 'react-i18next';
import { Loader } from '@shared/ui/loader';

export const RootLoader = observer(({ children }: { children: ReactNode }) => {
  const { rootLoading, loading } = useStore();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setNavigate(navigate);
  }, []);

  if (loading === LoadState.Loading) {
    return (
      <>
        {children}
        <Loader fullscreen blur />;
      </>
    );
  }

  if (
    rootLoading === LoadState.Success ||
    location.pathname === AppRoutesPaths.Landing
  ) {
    return <>{children}</>;
  }

  if (
    rootLoading === LoadState.Error &&
    location.pathname !== AppRoutesPaths.Landing
  ) {
    return (
      <div className='flex flex-col w-full h-screen justify-center items-center bg-white gap-6'>
        <span className='text-blue'>{t('error.loader')}</span>
      </div>
    );
  }

  if (
    rootLoading === LoadState.Loading &&
    location.pathname !== AppRoutesPaths.Landing
  ) {
    return (
      <div className='h-[100svh] bg-white'>
        <Loader />
      </div>
    );
  } else return null;
});