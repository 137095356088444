import React, { ComponentType } from 'react';
import { NavigationProvider } from './context';

const withNavigation = <P extends object>(
  WrappedComponent: ComponentType<P>,
) => {
  const Wrapper: React.FC<P> = (props) => {
    return (
      <NavigationProvider>
        <WrappedComponent {...props} />
      </NavigationProvider>
    );
  };

  return Wrapper;
};

export default withNavigation;
