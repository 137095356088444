import { DeskDragButton } from '@features/deskDragButton';
import { DeskNameInput } from '@features/deskNameInput';
import { DeskOptionsMenu } from '@features/deskOptionsMenu';
import { NewTaskButton } from '@features/newTaskButton';
import { darkTheme } from '@hooks/useTelegram';
import { useRef } from 'react';
import { DeskHeaderProps } from '../types';

export const DeskHeader = ({ desk }: DeskHeaderProps) => {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <header
      className={`flex select-none items-center pb-4 ${darkTheme ? 'text-white' : 'text-black'}`}
    >
      {!desk.parent_id && <DeskDragButton />}
      <DeskNameInput desk={desk} ref={ref} />
      <div className='ml-auto flex items-center gap-5'>
        {!desk.child?.length && (
          <NewTaskButton insideDesk={true} deskId={desk.id} />
        )}
        <DeskOptionsMenu inputRef={ref} desk={desk} />
      </div>
    </header>
  );
};
