import useScreenSize from '@hooks/getScreenSize';
import { EnterIcon } from '@pages/icons/enterIcon';
import { Spinner } from '@shared/ui/spinner';
import { AnimatePresence, motion } from 'framer-motion';
import { InputProps } from './types';

export const Input = ({
  showSendButton,
  preIcon,
  onSend,
  loading,
  ...props
}: InputProps) => {
  const { sm } = useScreenSize();

  const onSendHandler = async () => {
    try {
      await onSend?.();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className='relative w-full'>
      <input
        {...props}
        disabled={props.disabled || loading}
        className={`${props.className} w-full rounded-[20px] border border-solid border-[#D1D1D6] p-[5px_16px] outline-none placeholder:text-[#AEAEB2] focus:outline-none sm:rounded-normal sm:p-[12px_16px] ${showSendButton && '!pr-[40px] sm:!pr-[48px]'} ${preIcon && '!pl-[40px]'}`}
      />
      {preIcon && (
        <div className='absolute left-3 top-[7px] h-5 cursor-pointer text-[#AEAEB2] sm:top-[14px]'>
          {preIcon}
        </div>
      )}

      <AnimatePresence>
        {loading && (
          <Spinner
            className='absolute right-[14px] top-[7px] sm:top-[14px]'
            width={20}
            height={20}
            thickness={2}
          />
        )}
        {showSendButton && !loading && (
          <motion.div
            initial={{ opacity: 0, pointerEvents: 'none' }}
            animate={{
              opacity: 1,
              pointerEvents: 'all',
            }}
            exit={{
              opacity: 0,
              pointerEvents: 'none',
            }}
            onClick={onSendHandler}
            className='absolute right-3 top-[7px] cursor-pointer sm:top-3'
          >
            <EnterIcon width={sm ? 20 : 24} height={sm ? 20 : 24} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
