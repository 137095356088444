import { LoadState } from '@@types/loadState';
import {
  DeskTypes,
  IAllTasks,
  IChat,
  IChatInfo,
  IDesk,
  IFile,
  IMessage,
  ITask,
  IUser,
} from '@@types/types';
import { getFullName } from '@shared/lib/stringUtils';
import { RepeatTypesEnum } from '@ui/Calendar/types';
import { action, computed, observable } from 'mobx';
import moment from 'moment';

export class Store {
  @observable
  rootLoading = LoadState.Initial;

  @observable
  locale = 'ru';

  @observable
  loading = LoadState.Initial;

  @observable
  chatInfo: IChatInfo | undefined;

  @observable
  allTasks: ITask[] = [];

  @observable
  allChats: IChat[] = [];

  @observable
  contacts: IUser[] = [];

  @observable
  tasks: IAllTasks = {};

  @observable
  selectedTask: ITask | null = null;

  @observable
  chat_id: number | undefined = undefined;

  @observable
  user_id: number | undefined = undefined;

  @observable
  user: IUser | null = null;

  @computed
  get activeDesks() {
    return (
      this.chatInfo?.desks
        .filter((e) => !e.parent_id)
        .sort((a, b) => a.position - b.position) ?? []
    );
  }

  @computed
  get droppableDesks() {
    if (!this.chatInfo?.desks) return [];

    const res: IDesk[] = [];

    this.chatInfo.desks.forEach((d) => {
      if (d.parent_id) return;
      if (!d.child?.length) {
        res.push(d);
      } else {
        d.child.forEach((e) =>
          !e.name ? res.push({ ...e, name: d.name }) : res.push(e),
        );
      }
    });

    return res;
  }

  @computed
  get sortedChats() {
    return this.allChats.sort((a, b) => {
      if (a.is_pinned !== b.is_pinned) {
        return b.is_pinned - a.is_pinned;
      }
      const lastTaskA =
        a.last_task?.[a.last_task.length - 1]?.created_at ?? null;
      const lastTaskB =
        b.last_task?.[b.last_task.length - 1]?.created_at ?? null;

      if (lastTaskA && lastTaskB) {
        return new Date(lastTaskB).getTime() - new Date(lastTaskA).getTime();
      } else if (lastTaskA) {
        return -1;
      } else if (lastTaskB) {
        return 1;
      }

      return a.title.localeCompare(b.title);
    });
  }

  @computed
  get activeMyTasks() {
    return this.allTasks.filter(
      (e) => e.status !== 2 && e.desk?.type !== DeskTypes.DONE,
    );
  }

  @computed
  get mergedSortedTasks() {
    const tasks: ITask[] = [];
    Object.keys(this.tasks).forEach((key) => {
      this.tasks[key].forEach((e) => tasks.push(e));
    });

    tasks
      .sort((a, b) => a.number - b.number)
      .sort((a, b) => {
        if (a.deadline === null) return 1;
        if (b.deadline === null) return -1;

        const deadlineA = moment(a.deadline);
        const deadlineB = moment(b.deadline);

        return deadlineA.diff(deadlineB);
      })
      .sort((a, b) => b.priority - a.priority);

    return tasks.filter((e) => e.status !== 2);
  }

  @action.bound
  setChats(chats: IChat[]) {
    const defaultChats = chats.filter((c) => c.type !== 'personal_message');
    const personalMessageChats = chats
      .filter((c) => c.type === 'personal_message')
      .map((c) => ({
        ...c,
        title: getFullName(
          c.users_personal_message[0].first_name,
          c.users_personal_message[0].last_name,
        ),
        photo_url: c.users_personal_message[0].photo_url,
      }));
    this.allChats = [...defaultChats, ...personalMessageChats];
  }

  @action.bound
  setChatInfo(chat: IChatInfo) {
    chat.title =
      chat.title ??
      getFullName(chat.users[0].first_name, chat.users[0].last_name);
    this.chatInfo = chat;
  }

  @action.bound
  updateTask(task: ITask) {
    for (const key in this.tasks) {
      if (this.tasks[key].find((e) => e.id === task.id))
        this.tasks[key] = this.tasks[key].map((e) =>
          e.id === task.id ? task : e,
        );
    }
  }

  @action.bound
  updateChat(chat: IChat) {
    this.allChats = this.allChats.map((e) => (e.id === chat.id ? chat : e));
  }

  @action.bound
  updateChatInfo(chat: IChatInfo) {
    this.chatInfo = chat;
  }

  @action.bound
  renameKey(id: number, newName: string) {
    this.chatInfo!.desks = this.chatInfo!.desks.map((e) =>
      e.id === id ? { ...e, name: newName } : e,
    );
  }

  @action.bound
  hideColumn(parentId: number, columnId: number) {
    const index = this.chatInfo!.desks.findIndex((d) => d.id === parentId)!;
    this.chatInfo!.desks[index] = {
      ...this.chatInfo!.desks[index],
      child: this.chatInfo!.desks[index].child.map((e) =>
        e.id === columnId ? { ...e, name: '' } : e,
      ),
    };
  }

  @action.bound
  deleteDesk(deskId: number) {
    return this.chatInfo?.desks.map((e) =>
      e.id === deskId ? { ...e, deleted: 1 } : e,
    );
  }

  @action.bound
  setChatId(id: number) {
    this.chat_id = id;
  }

  @action.bound
  setLocale(str: string) {
    this.locale = str;
  }

  @action.bound
  setUserId(id: number) {
    this.user_id = id;
  }

  @action.bound
  setUser(user: IUser) {
    this.user = user;
  }

  @action.bound
  resetSelectedTask() {
    this.selectedTask = null;
  }

  @action.bound
  setEmptyTask(deskId?: number) {
    this.selectedTask = {
      id: null,
      chat_id: 0,
      creator_id: 0,
      title: '',
      status: 0,
      deadline: null,
      subtasks: [],
      number: 0,
      created_at: '',
      updated_at: '',
      responsible: [],
      messages: [],
      priority: 0,
      file: [],
      desk: this.chatInfo?.desks.find((e) =>
        deskId ? e.id === deskId : e.type === DeskTypes['NEW'],
      ),
      newFiles: [],
      repeat: {
        type: RepeatTypesEnum.NEVER,
        value: 'never',
        time: '00:00',
      },
    };
  }

  @action.bound
  addFileToSelectedTask(file: IFile) {
    this.selectedTask?.file.push(file);
  }

  @action.bound
  addMessageToSelectedTask(message: IMessage) {
    this.selectedTask?.messages.push(message);
  }

  @action.bound
  setRootLoading(state: LoadState) {
    this.rootLoading = state;
  }

  @action.bound
  moveDesk(startIndex: number, targetIndex: number) {
    if (!this.chatInfo?.desks) return;
    const updatedDesks = Array.from(this.activeDesks);
    const [movedDesk] = updatedDesks.splice(startIndex, 1);
    updatedDesks.splice(targetIndex, 0, movedDesk);

    updatedDesks.forEach((desk, index) => {
      desk.position = index;
    });

    this.chatInfo.desks = this.chatInfo.desks.map((e) =>
      !updatedDesks.some((i) => i.id === e.id)
        ? { ...e }
        : updatedDesks.find((i) => e.id === i.id)!,
    );
  }
}
