import { useTranslation } from 'react-i18next';

export const Header = () => {
  const { t } = useTranslation();

  return (
    <header className='header'>
      <div className='wrapper header__wrapper'>
        <a href='#' className='logo'>
          <img src='assets/fav-icon.svg' alt='' />
          BOARDS
        </a>

        <a
          href='https://t.me/TgBoards_bot'
          target='_blank'
          className='button button--small'
        >
          {t('landing.button.start')}
          <svg
            width='8'
            height='14'
            viewBox='0 0 8 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clip-path='url(#clip0_313_25213)'>
              <path
                d='M1.19342 13.4708C1.07529 13.5864 0.915083 13.6514 0.74803 13.6514C0.580976 13.6514 0.420766 13.5864 0.302644 13.4708C0.184521 13.3551 0.118162 13.1983 0.118164 13.0348C0.118167 12.8712 0.184531 12.7144 0.302657 12.5988L5.94598 7.07433L0.302657 1.54991C0.184531 1.43428 0.118167 1.27744 0.118164 1.11391C0.118162 0.950376 0.184521 0.79354 0.302644 0.677903C0.420766 0.562266 0.580976 0.4973 0.74803 0.497299C0.915083 0.497296 1.07529 0.562255 1.19342 0.677889L7.28214 6.63832C7.34064 6.69558 7.38704 6.76355 7.41869 6.83836C7.45035 6.91317 7.46664 6.99336 7.46664 7.07433C7.46664 7.15531 7.45035 7.23549 7.41869 7.3103C7.38704 7.38511 7.34064 7.45309 7.28214 7.51034L1.19342 13.4708Z'
                fill='#303030'
              />
            </g>
            <defs>
              <clipPath id='clip0_313_25213'>
                <rect
                  width='13.9998'
                  height='7.99986'
                  fill='white'
                  transform='matrix(0 1 -1 0 8 0)'
                />
              </clipPath>
            </defs>
          </svg>
        </a>
      </div>
    </header>
  );
};
