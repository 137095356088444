import { highlightLinkInString } from '@shared/lib/stringUtils';
import { observer } from 'mobx-react-lite';
import { TaskInfoProps } from '../types';

export const TaskInfo = observer(
  ({ task, extended = false, isInline }: TaskInfoProps) => {
    return (
      <>
        <span className='text-blue'>/{task.number} </span>
        <span className='mr-[5px]'>
          {highlightLinkInString(task.title ?? '')}
        </span>
        {task.subtasks.length !== 0 && (
          <div className='mr-[5px] inline-flex h-[21px] min-w-[39px] items-center justify-center rounded-[4px] bg-subTasksBackground text-base text-white'>
            {task.subtasks.reduce(
              (prev, cur) => (cur.status == 'complete' ? prev + 1 : prev),
              0,
            )}{' '}
            / {task.subtasks.length}
          </div>
        )}
        {Array.isArray(task.responsible) &&
          task.responsible.length > 0 &&
          isInline &&
          !extended &&
          task.responsible.map((r) => {
            return r.photo_url ? (
              <img
                key={r.id}
                src={r.photo_url}
                className='mr-[5px] inline h-[21px] w-[21px] rounded-[100px] bg-grey object-contain align-middle'
              />
            ) : (
              <div className='mr-[5px] inline-flex min-h-[21px] min-w-[21px] items-center justify-center rounded-[100px] bg-blue align-middle text-small font-bold text-white' key={r.id}>
                {(r.last_name?.[0]?.toUpperCase() ?? '') +
                  (r.first_name?.[0]?.toUpperCase() ?? '')}
              </div>
            );
          })}
      </>
    );
  },
);
