import { LoadState } from '@@types/loadState';
import { RootLoader } from '@common/RootLoader/RootLoader';
import { ScrollToTop } from '@common/scrollToTop';
import { AppRoutes, AppRoutesPaths, AppRoutesType } from '@config/navigation';
import { withConfigs } from '@config/withConfigs';
import { useLogic, useStore } from '@hooks/storeHook';
import { getQueryParams } from '@utils/getQueryParams';
import { AnimatePresence } from 'framer-motion';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/ru';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';
import './App.css';
import './common/commonStyles.css';
import './common/telegramTheme.css';
import { TaskModalProvider } from '@pages/main/lib/context';
import { TaskLoader } from '@features/taskLoader';

export const App = withConfigs(() => {
  const location = useLocation();
  const logic = useLogic();
  const navigate = useNavigate();
  const tg = window.Telegram.WebApp;
  const { setChatId, setUserId, setRootLoading, setLocale } = useStore();
  const [searchParams] = useSearchParams();
  const { i18n } = useTranslation();

  useEffect(() => {
    tg.SettingsButton.show();
    tg.SettingsButton.onClick(() => navigate(AppRoutesPaths.Settings));
  }, [tg]);

  useEffect(() => {
    moment.locale(i18n.language);
    setLocale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const { chatId } = getQueryParams(
      tg.initDataUnsafe?.start_param ?? searchParams.get('chatId'),
    );

    const userId = tg.initDataUnsafe?.user?.id;

    if (chatId) {
      setChatId(+chatId);
    } else if (userId && !chatId) {
      setChatId(0);
    }

    const decodedData = encodeURIComponent(
      decodeURIComponent(
        decodeURIComponent(
          decodeURIComponent(window.location.hash.replace('#', '')),
        ),
      ),
    )
      .replaceAll('%3D', '=')
      .replaceAll('%26', '&');

    const initData = tg.initData.length > 0 ? tg.initData : decodedData;

    const languageCode =
      tg.initDataUnsafe?.user?.language_code ??
      decodeURIComponent(initData).match(/language_code":"(.*?)"/)?.[1] ??
      localStorage.getItem('language_code');

    if (languageCode) i18n.changeLanguage(languageCode);

    if (!initData) {
      setRootLoading(LoadState.Error);
      return;
    }
    logic.initializeApiLogic(initData);

    if (userId || decodeURIComponent(initData).match(/"id":(\d+)/)?.[1]) {
      userId
        ? setUserId(userId)
        : setUserId(
            Number(decodeURIComponent(initData).match(/"id":(\d+)/)![1]),
          );
    }

    const init = async () => {
      await logic.loadInitialData();
      if (chatId == 0 || (userId && !chatId)) {
        navigate(AppRoutesPaths.My);
      }
    };

    init();
  }, []);

  const renderRoutes = (routes?: AppRoutesType) => {
    if (!routes) {
      return null;
    }
    return routes.map((route) => (
      <Route key={route.name} element={route.element} path={route.path}>
        {renderRoutes(route.childs)}
      </Route>
    ));
  };

  return (
    <ScrollToTop>
      <RootLoader>
        <TaskModalProvider>
          <AnimatePresence initial={false} mode='wait'>
            <TaskLoader />
            <Routes location={location} key={location.key}>
              {renderRoutes(AppRoutes)}
              <Route path='*' element={<Navigate to='/' replace />} />
            </Routes>
          </AnimatePresence>
        </TaskModalProvider>
      </RootLoader>
      <YMInitializer
        accounts={[97905569]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
        }}
      />
    </ScrollToTop>
  );
});
