import { motion } from 'framer-motion';

export const SettingsSection = ({ children }: React.PropsWithChildren) => {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className='flex w-full flex-col items-center'
    >
      {children}
    </motion.section>
  );
};
