import Logo from '@assets/logo.png';
import { useStore } from '@hooks/storeHook';
import { darkTheme, useTelegram } from '@hooks/useTelegram';
import { Input } from '@shared/ui/input';
import clx from 'classnames';
import { observer } from 'mobx-react-lite';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuElemProps, viewsTypes } from '../types';
import { ProjectCard } from './ProjectCard';
import { TaskCard } from './TaskCard';
import { ContactCard } from './ContactCard';

export const Menu = observer(() => {
  const [view, setView] = useState<viewsTypes>(viewsTypes.PROJECTS);
  const [searchTerm, setSearchTerm] = useState('');
  const { sortedChats, activeMyTasks, contacts } = useStore();
  const isTg = useTelegram();

  const { t } = useTranslation();

  const onChangeView = (v: viewsTypes) => {
    setView(v);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredDefaultChats = sortedChats.filter(
    (chat) =>
      chat.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      chat.type !== 'personal_message',
  );

  const filteredPersonalMessageChats = sortedChats.filter(
    (chat) =>
      chat.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      chat.type === 'personal_message',
  );

  const filteredTasks = activeMyTasks.filter((task) =>
    task.title?.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const filteredContacts = contacts.filter(
    (contact) =>
      contact.first_name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !filteredPersonalMessageChats.some(
        (c) => c.users_personal_message[0].id === contact.id,
      ),
  );

  return (
    <aside
      className={clx(
        'flex h-full flex-col bg-greySecondary -tracking-[0.4]',
        { 'min-w-[295px] max-w-[295px] pt-9': !isTg },
        { 'h-screen w-screen pt-3': isTg },
        { '!bg-darkCardBackground': darkTheme && isTg },
      )}
    >
      {!isTg && (
        <div
          className={clx(
            'mb-[26px] flex items-center gap-6',
            { 'px-[15px]': isTg },
            { 'px-[11px]': !isTg },
          )}
        >
          <img src={Logo} width={50} height={50} />
          <h1 className='text-[36px]'>BOARDS</h1>
        </div>
      )}

      <Input
        className={clx(
          'mb-[18px] !h-[33px] border-none !text-[15px] placeholder:font-normal',
          { 'ml-[11px] !w-[calc(100%_-_22px)] !rounded-[16px]': !isTg },
          { 'ml-[16px] !w-[calc(100%_-_30px)] !rounded-[10px]': isTg },
        )}
        placeholder={t('input.search')}
        value={searchTerm}
        onChange={handleSearchChange}
      />

      <menu
        className={clx(
          'flex',
          { 'gap-[24px] px-[15px]': isTg },
          { 'gap-[14px] px-[11px]': !isTg },
          { '!text-white': darkTheme && isTg },
        )}
      >
        <MenuElem
          viewType={viewsTypes.PROJECTS}
          onChangeView={onChangeView}
          active={view === viewsTypes.PROJECTS}
        />
        <MenuElem
          viewType={viewsTypes.CONTACTS}
          onChangeView={onChangeView}
          active={view === viewsTypes.CONTACTS}
        />
        {isTg && (
          <MenuElem
            viewType={viewsTypes.TASKS}
            onChangeView={onChangeView}
            active={view === viewsTypes.TASKS}
          />
        )}
      </menu>
      <ul
        className={clx(
          'flex h-full w-full flex-col overflow-x-hidden rounded-[10px_10px_0_0] bg-white',
          {
            '!bg-[#191919] text-white': darkTheme && isTg,
          },
        )}
      >
        {view === viewsTypes.TASKS &&
          filteredTasks.map((e, i) => (
            <Fragment key={i}>
              <TaskCard task={e} />
              {i !== filteredTasks.length - 1 && (
                <div
                  className={clx(
                    'min-h-[1px] w-full self-end bg-greySecondary',
                    { '!bg-darkCardBackground': darkTheme },
                  )}
                />
              )}
            </Fragment>
          ))}
        {view === viewsTypes.CONTACTS &&
          filteredPersonalMessageChats.map((e, i) => (
            <Fragment key={i}>
              <ProjectCard chat={e} />
              {(i !== filteredPersonalMessageChats.length - 1 ||
                (filteredContacts.length > 0 && searchTerm)) && (
                <div
                  className={clx(
                    'min-h-[1px] w-[calc(100%_-_60px)] self-end bg-greySecondary',
                    { '!bg-darkCardBackground': darkTheme },
                  )}
                />
              )}
            </Fragment>
          ))}
        {view === viewsTypes.CONTACTS &&
          filteredContacts.map((e, i) => (
            <Fragment key={i}>
              <ContactCard contact={e} />
              {i !== filteredContacts.length - 1 && (
                <div
                  className={clx(
                    'min-h-[1px] w-[calc(100%_-_60px)] self-end bg-greySecondary',
                    { '!bg-darkCardBackground': darkTheme },
                  )}
                />
              )}
            </Fragment>
          ))}
        {view === viewsTypes.PROJECTS &&
          filteredDefaultChats.map((e, i) => (
            <Fragment key={i}>
              <ProjectCard chat={e} />
              {i !== filteredDefaultChats.length - 1 && (
                <div
                  className={clx(
                    'min-h-[1px] w-[calc(100%_-_60px)] self-end bg-greySecondary',
                    { '!bg-darkCardBackground': darkTheme },
                  )}
                />
              )}
            </Fragment>
          ))}
      </ul>
    </aside>
  );
});

const MenuElem = ({ viewType, onChangeView, active }: MenuElemProps) => {
  const { t } = useTranslation();

  return (
    <li
      className={clx('h-[28px] cursor-pointer list-none font-light', {
        'font-normal after:relative after:top-[6px] after:block after:h-[2px] after:w-full after:rounded-[4px_4px_0_0] after:bg-blue after:content-[""]':
          active,
      })}
      onClick={() => onChangeView(viewType)}
    >
      {t(viewType)}
    </li>
  );
};
