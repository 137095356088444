import { useTranslation } from 'react-i18next';

export const Advantages = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const a1ImageSrc =
    currentLanguage === 'ru' ? 'assets/a-1.png' : 'assets/a-1-eng.png';

  const a2ImageSrc =
    currentLanguage === 'ru' ? 'assets/a-2.png' : 'assets/a-2-eng.png';

  const a3ImageSrc =
    currentLanguage === 'ru' ? 'assets/a-3.png' : 'assets/a-3-eng.png';

  const a4ImageSrc =
    currentLanguage === 'ru' ? 'assets/a-4.png' : 'assets/a-4-eng.png';

  return (
    <section className='advantages'>
      <div className='wrapper advantages__wrapper'>
        <h2 className='advantages__title'>{t('landing.advantages.title')}</h2>

        <div className='advantages__wrap'>
          <div className='advantages__wrap-left'>
            <h3 className='advantages__card-title'>
              {t('landing.advantages.adv1.title')}
            </h3>
            <p className='advantages__card-desc'>
              {t('landing.advantages.adv1.offer')}
            </p>
          </div>

          <img src={a1ImageSrc} alt='' />

          <a
            href='https://t.me/TgBoards_bot'
            target='_blank'
            className='button button--big'
          >
            {t('landing.button.getApp')}
          </a>
        </div>

        <div className='advantages__wrap advantages__wrap--reverse'>
          <div className='advantages__wrap-left'>
            <h3 className='advantages__card-title'>
              {t('landing.advantages.adv2.title')}
            </h3>
            <p className='advantages__card-desc'>
              {t('landing.advantages.adv2.offer')}
            </p>
          </div>

          <img src={a2ImageSrc} alt='' />
        </div>

        <div className='advantages__wrap'>
          <div className='advantages__wrap-left'>
            <h3 className='advantages__card-title'>
              {t('landing.advantages.adv3.title')}
            </h3>
            <p className='advantages__card-desc'>
              {t('landing.advantages.adv3.offer')}
            </p>
          </div>

          <img src={a3ImageSrc} alt='' />
        </div>

        <div className='advantages__wrap advantages__wrap--reverse'>
          <div className='advantages__wrap-left'>
            <h3 className='advantages__card-title'>
              {t('landing.advantages.adv4.title')}
            </h3>
            <p className='advantages__card-desc'>
              {t('landing.advantages.adv4.offer')}
            </p>
          </div>

          <img src={a4ImageSrc} alt='' />
        </div>
      </div>
    </section>
  );
};
