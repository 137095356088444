import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { useLogic, useStore } from '@hooks/storeHook';
import { CloseDrawerIcon } from '@pages/icons/closeDrawer';
import { observer } from 'mobx-react-lite';
import Drawer from 'react-bottom-drawer';
import { useTranslation } from 'react-i18next';
import { SelectorOfTargetTableProps } from '../types';

export const SelectorOfTargetTable = observer(
  ({ desk, onCloseDrawer, visible }: SelectorOfTargetTableProps) => {
    const { droppableDesks } = useStore();
    const { t } = useTranslation();
    const logic = useLogic();

    const onPickNewDesk = async (transferDeskId: number) => {
      await logic.deleteBoard(desk!.id, transferDeskId);
      extraLightHapticFeedback();
      onCloseDrawer();
    };

    return (
      <Drawer onClose={onCloseDrawer} className='drawer' isVisible={visible}>
        <div
          onClick={onCloseDrawer}
          className='absolute right-[15px] top-[15px]'
        >
          <CloseDrawerIcon />
        </div>
        <div className='flex w-full flex-col gap-[15px]'>
          <span className='w-full text-center text-large font-bold'>
            {t('dragTasks')}
          </span>
          {droppableDesks
            .filter((e) => e.id !== desk.id)
            .map((desk) => (
              <div
                onClick={() => onPickNewDesk(desk.id)}
                className='flex w-full items-center gap-[5px] border-0 border-b-[1px] border-solid border-border-grey py-[10px]'
                key={desk.id}
              >
                <span className='text-large font-bold'>{desk.name}</span>
                <span className='ml-auto cursor-pointer text-large text-blue'>
                  {t('button.select')}
                </span>
              </div>
            ))}
        </div>
      </Drawer>
    );
  },
);
