import { Landing } from '@pages/landing';
import Main from '@pages/main';
import { MyPage } from '@pages/my';
import { SettingsPage } from '@pages/settings';

import { NavigateFunction, NavigateOptions } from 'react-router-dom';

let navigateFunc: NavigateFunction | null = null;

export const setNavigate = (navigate: NavigateFunction) => {
  navigateFunc = navigate;
};

export const navigateTo = (route: string, options?: NavigateOptions) => {
  navigateFunc?.(route, options);
};

export enum AppRoutesPaths {
  Boards = '/k',
  Landing = '/',
  Settings = '/settings',
  My = '/my',
}

export type AppRoutesType = {
  name: string;
  path: string;
  element?: JSX.Element;
  childs?: AppRoutesType;
}[];

export const AppRoutes: AppRoutesType = [
  {
    name: 'My',
    path: AppRoutesPaths.My,
    element: <MyPage />,
  },
  {
    name: 'Boards',
    path: AppRoutesPaths.Boards,
    element: <Main />,
  },
  {
    name: 'Home',
    path: AppRoutesPaths.Landing,
    element: <Landing />,
  },
  {
    name: 'Settings',
    path: AppRoutesPaths.Settings,
    element: <SettingsPage />,
  },
];
