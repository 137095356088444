import { ITask } from '@@types/types';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { useStore } from '@hooks/storeHook';
import { useTranslation } from 'react-i18next';

interface IBoardSelector {
  task: ITask;
  setTask: (field: string, value: ITask[keyof ITask]) => void;
  onHide: () => void;
}

export const BoardSelector = ({ task, onHide, setTask }: IBoardSelector) => {
  const { droppableDesks: desks } = useStore();
  const { t } = useTranslation();

  const onSelectDesk = (value: string) => {
    const desk = desks.find((item) => item.name === value);
    if (desk) setTask('desk', desk);
    extraLightHapticFeedback();
    onHide();
  };

  return desks
    ?.filter((desk) => desk.id !== task.desk?.id)
    .map((desk) => (
      <div
        onClick={() => onSelectDesk(desk.name)}
        className='flex w-full cursor-pointer items-center gap-[5px] border-0 border-b-[1px] border-solid border-border-grey py-[10px]'
        key={desk.id}
      >
        <span className='text-large font-medium'>{desk.name}</span>
        <span className='ml-auto text-large text-blue'>
          {t('button.select')}
        </span>
      </div>
    ));
};
