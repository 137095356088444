import { appColors } from '@config/theme';
import { motion } from 'framer-motion';

interface ISwitcher {
  elements: string[];
  activeIndex: number;
  onClick: (index: number) => void;
}

export const Switcher = ({ elements, activeIndex, onClick }: ISwitcher) => (
  <div
    style={{
      background: appColors.greySlider,
    }}
    className='relative flex min-h-[32px] w-full items-center rounded-[9px] bg-boardBackground p-[2px]'
  >
    <motion.div
      layout
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      style={{
        zIndex: 0,
        boxShadow: '0px 3px 1px 0px rgba(0, 0, 0, 0.04)',
        backgroundColor: 'white',
        position: 'absolute',
        top: '2px',
        bottom: '2px',
        borderRadius: '9px',
        width: `calc(100% /${elements.length} - 4px)`,
        left: `calc(${activeIndex * (100 / elements.length)}% + 2px)`,
      }}
    />
    {elements.map((item, index) => (
      <div
        key={index}
        className='z-10 flex h-full w-full cursor-pointer items-center justify-center text-bigger tracking-sm text-black'
        onClick={() => onClick(index)}
      >
        {item}
      </div>
    ))}
  </div>
);
