export enum RepeatTypesEnum {
  NEVER = 'never',
  EVERY_YEAR = 'year',
  EVERY_MONTH = 'month',
  EVERY_WEEKDAY = 'week',
  EVERYDAY = 'day',
}

export type RepeatOption = {
  label: string;
  value: RepeatTypesEnum;
};
