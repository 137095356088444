import { ITask } from '@@types/types';
import FlameIcon from '@assets/icons/flame.png';
import { CalendarIcon } from '@pages/icons/calendarIcon';
import { MoreActionsIcon } from '@pages/icons/moreActionsIcon';
import { CalendarComponent } from '@ui/Calendar/Calendar';
import { RepeatTypesEnum } from '@ui/Calendar/types';
import {
  calculateNextWeekLastDay,
  calculateThisWeekLastDay,
} from '@utils/calculateDeadline';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IDateSelector {
  task: ITask;
  onSave: (
    date: Date | null,
    priority?: true,
    repeat?: RepeatTypesEnum,
  ) => void;
}

export const DateSelector = ({ task, onSave }: IDateSelector) => {
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const { t } = useTranslation();

  return (
    <div className='flex w-full flex-col items-center'>
      {!isShowCalendar && (
        <div className='flex w-full flex-col bg-white py-[2px]'>
          <div
            className='flex cursor-pointer items-center gap-4 py-[12px] pl-4'
            onClick={() => onSave(new Date(), true)}
          >
            <div className='flex min-h-[21px] min-w-[21px] items-center justify-center'>
              <img src={FlameIcon} width={14} height={18} />
            </div>
            <p className='text-lg m-0 font-normal'>{t('dateType.urgently')}</p>
          </div>
          <div className='w-[calc(100%_-_60px)] self-end border-0 border-t-[0.5px] border-solid border-[#d9d9d9]' />
          {(!(moment().day() === 5 && moment().hour() >= 19) ||
            moment().day() < 5) && (
            <>
              <div
                className='flex cursor-pointer items-center gap-4 py-[12px] pl-4'
                onClick={() => onSave(calculateThisWeekLastDay())}
              >
                <div className='flex min-h-[21px] min-w-[21px] items-center justify-center rounded-[100px] bg-green'>
                  <span className='text-[13px] text-white'>I</span>
                </div>
                <p className='text-lg m-0 font-normal'>
                  {t('dateType.thisWeek')}
                </p>
              </div>
              <div className='w-[calc(100%_-_60px)] self-end border-0 border-t-[0.5px] border-solid border-[#d9d9d9]' />
            </>
          )}
          <div
            className='flex cursor-pointer items-center gap-4 py-[12px] pl-4'
            onClick={() => onSave(calculateNextWeekLastDay())}
          >
            <div className='flex min-h-[21px] min-w-[21px] items-center justify-center rounded-[100px] bg-blue'>
              <span className='text-[13px] text-white'>II</span>
            </div>
            <p className='text-lg m-0 font-normal'>{t('dateType.nextWeek')}</p>
          </div>
          <div className='w-[calc(100%_-_60px)] self-end border-0 border-t-[0.5px] border-solid border-[#d9d9d9]' />
          <div
            className='flex cursor-pointer items-center gap-4 py-[12px] pl-4'
            onClick={() => setIsShowCalendar(true)}
          >
            <div className='relative -left-[2px] h-[23px] w-[21px]'>
              <CalendarIcon width={21} height={23} fill='#A2ACB0' />
            </div>
            <p className='text-lg m-0 font-normal'>
              {t('dateType.selectDate')}
            </p>
          </div>
          <div className='w-[calc(100%_-_60px)] self-end border-0 border-t-[0.5px] border-solid border-[#d9d9d9]' />
          <div
            className='flex cursor-pointer items-center gap-4 py-[12px] pl-4'
            onClick={() => onSave(null)}
          >
            <div className='h-[21px] w-[21px] rotate-90'>
              <MoreActionsIcon width={21} height={21} />
            </div>
            <p className='text-lg m-0 font-normal'>{t('dateType.noDate')}</p>
          </div>
        </div>
      )}

      {isShowCalendar && (
        <CalendarComponent
          defaultRepeatType={task.repeat?.type}
          onSave={onSave}
          defaultValue={task.deadline && moment(task.deadline).format('')}
        />
      )}
    </div>
  );
};
