import { useLogic, useStore } from '@hooks/storeHook';
import { useTelegram } from '@hooks/useTelegram';
import { TaskModal } from '@ui/TaskModal/TaskModal';
import { AnimatePresence } from 'framer-motion';
import { createContext, useContext, ReactNode, useState } from 'react';

interface ITaskModalContext {
  isShowModal: boolean;
  showModal: () => void;
  hideModal: () => void;
  getTask: (chatId: number, id: number) => void;
  newTaskOpen: (deskId: number | undefined) => void;
}

const TaskModalContext = createContext<ITaskModalContext | undefined>(
  undefined,
);

export const TaskModalProvider = ({ children }: { children: ReactNode }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const logic = useLogic();
  const [isLoading, setIsLoading] = useState(false);
  const isTg = useTelegram();

  const { setEmptyTask } = useStore();

  const showModal = () => setIsShowModal(true);

  const getTask = async (chatId: number, id: number) => {
    setIsLoading(true);
    showModal();
    try {
      await logic.getTask(chatId, id!);
    } finally {
      setIsLoading(false);
    }
  };

  const newTaskOpen = async (deskId: number | undefined) => {
    setEmptyTask(deskId);
    showModal();
  };

  const hideModal = () => {
    if (!isTg) {
      setIsShowModal(false);
      return;
    }

    window.Telegram.WebApp.MainButton.hide();
    setTimeout(() => {
      setIsShowModal(false);
    }, 200);
  };

  return (
    <TaskModalContext.Provider
      value={{ isShowModal, showModal, hideModal, getTask, newTaskOpen }}
    >
      {children}
      <AnimatePresence>
        {isShowModal && <TaskModal onHide={hideModal} loading={isLoading} />}
      </AnimatePresence>
    </TaskModalContext.Provider>
  );
};

export const useTaskModalContext = (): ITaskModalContext => {
  const context = useContext(TaskModalContext);
  if (!context) {
    throw new Error(
      'useTaskModalContext must be used within a TaskModalProvider',
    );
  }
  return context;
};
