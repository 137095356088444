import { appColors } from '@config/theme';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

export const Button = ({
  green,
  red,
  classname = '',
  children,
  icon,
  disabled,
  onClick,
}: {
  green?: boolean;
  red?: boolean;
  classname?: string;
  icon?: JSX.Element;
  disabled?: boolean;
  onClick?: () => void;
} & PropsWithChildren) => {
  const bgColor = green
    ? appColors.greenButton
    : red
      ? appColors.redButton
      : appColors.blue;
  const textColor = green ? appColors.green : red ? appColors.red : 'white';

  return (
    <button
      onClick={onClick}
      style={{ color: textColor, backgroundColor: bgColor }}
      className={classNames(
        `flex min-h-[42px] cursor-pointer items-center justify-center gap-[8px] rounded border-0 uppercase ${classname}`,
        { '!cursor-default !bg-grey': disabled },
      )}
      disabled={disabled}
    >
      {icon}
      {children}
    </button>
  );
};
