import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { AppRoutesPaths, navigateTo } from '@config/navigation';
import { useLogic, useStore } from '@hooks/storeHook';
import { darkTheme, useTelegram } from '@hooks/useTelegram';
import clx from 'classnames';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { ProjectCardProps } from '../types';
import { StarIcon } from '@pages/icons/startIcon';
import { FilledStarIcon } from '@pages/icons/filledStartIcon';
import { Avatar } from '@shared/ui/avatar';
import moment from 'moment';

export const ProjectCard = observer(({ chat }: ProjectCardProps) => {
  const logic = useLogic();
  const store = useStore();
  const [isHovered, setIsHovered] = useState(false);
  const isTg = useTelegram();

  const handleMouseEnter = () => !isTg && setIsHovered(true);
  const handleMouseLeave = () => !isTg && setIsHovered(false);

  const onClickHandler = async () => {
    store.chat_id = chat.id;
    await logic.switchProject();
    if (!isTg) {
      return;
    }
    extraLightHapticFeedback();
    navigateTo(AppRoutesPaths.Boards);
  };

  const onPinChat = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    const isChatPinned = Boolean(chat.is_pinned);
    await logic.updateChat({
      ...chat,
      is_pinned: Number(!isChatPinned) as 0 | 1,
    });
  };

  const isPinned = chat.is_pinned === 1;

  return (
    <li
      className={clx(
        `relative flex cursor-pointer items-center bg-transparent px-[10px] py-[5px] transition-colors duration-300`,
        { 'min-h-16': isTg },
        {
          'bg-[#191919]': darkTheme && (!isHovered || isPinned),
        },
        { 'bg-white': !darkTheme && (!isHovered || isPinned) },
        {
          '!bg-darkCardBackground':
            darkTheme && (isHovered || store.chat_id === chat.id),
        },
        {
          '!bg-greyHover':
            !darkTheme && (isHovered || store.chat_id === chat.id),
        },
      )}
      onClick={onClickHandler}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {chat.photo_url ? (
        <Avatar
          width={isTg ? 40 : 32}
          height={isTg ? 40 : 32}
          src={chat.photo_url}
          className='mr-[10px]'
        />
      ) : (
        <Avatar
          width={isTg ? 40 : 32}
          height={isTg ? 40 : 32}
          string={chat.title[0].toUpperCase()}
          className='mr-[10px]'
        />
      )}
      <div className='w-full'>
        <div className='flex justify-between'>
          <p
            className={`overflow-hidden overflow-ellipsis whitespace-nowrap ${!isTg && 'max-w-[200px]'}`}
          >
            {chat.title}
          </p>
          {chat.last_task?.[0] && isTg && (
            <span className='text-bigger text-grey-text'>
              {moment(chat.last_task[0].created_at).isSame(moment(), 'day')
                ? moment(chat.last_task[0].created_at).format('HH:mm')
                : moment(chat.last_task[0].created_at).format('DD.MM')}
            </span>
          )}
        </div>

        {chat.last_task?.[0] && isTg && (
          <p className='mt-[3px] max-w-[80%] overflow-hidden overflow-ellipsis whitespace-nowrap text-bigger text-grey-text'>
            {chat.last_task[0].title}
          </p>
        )}
      </div>
      <div
        className={clx(
          'absolute right-[10px] transition-opacity duration-300',
          {
            'opacity-100': isHovered || isPinned,
            'opacity-0': !isHovered && !isPinned,
            'top-[10px]': !isTg,
            'bottom-[10px]': isTg,
          },
        )}
        onClick={onPinChat}
      >
        {isPinned ? (
          <FilledStarIcon width={isTg ? 16 : 20} height={isTg ? 16 : 20} />
        ) : (
          <StarIcon width={isTg ? 16 : 20} height={isTg ? 16 : 20} />
        )}
      </div>
    </li>
  );
});
