import { IFile } from '@@types/types';
import Document from '@assets/icons/document.svg';
import DownloadIcon from '@assets/icons/download.svg';
import { CrossIcon } from '@pages/icons/crossIcon';
import { downloadFile } from '@shared/lib/downloadFile';
import classNames from 'classnames';
import { useState } from 'react';
import { FilePreview } from '../types';

type FileType = File | IFile;

export const DocumentPreview = ({
  file,
  handleDeleteFile,
  deletable,
}: FilePreview) => {
  const [isHovered, setIsHovered] = useState(false);
  const isIFile = (file: FileType): file is IFile => {
    return (file as IFile).preview !== undefined;
  };

  const handleDownload = () => {
    if (!isIFile(file)) return;
    downloadFile(file.original_name, file.task_id, file.tg_file_id);
  };

  return (
    <div
      className={classNames(
        'relative flex h-[100px] min-w-[100px] max-w-[100px] rounded-[8px] bg-greySlider transition-all duration-300 ease-in-out',
      )}
      onMouseEnter={() => isIFile(file) && setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={Document}
        alt={`Preview doc`}
        className={classNames(
          'm-auto w-[35px] transition-opacity duration-300',
          {
            'opacity-100': !isHovered,
            'opacity-50 blur-sm': isHovered,
          },
        )}
        onClick={handleDownload}
      />
      {isHovered && (
        <div className='absolute inset-0 cursor-pointer rounded-[8px] backdrop-blur-sm'>
          <img
            src={DownloadIcon}
            alt='Download'
            className='absolute left-1/2 top-1/2 w-[35px] -translate-x-1/2 -translate-y-1/2 transform'
            onClick={handleDownload}
          />
        </div>
      )}
      {deletable && <DeleteButton handleDeleteFile={handleDeleteFile} />}
    </div>
  );
};

const DeleteButton = ({
  handleDeleteFile,
}: Pick<FilePreview, 'handleDeleteFile'>) => {
  return (
    <div
      className='absolute -right-1 -top-1 flex h-5 w-5 cursor-pointer items-center justify-center rounded-[50%] bg-grey-text text-white'
      onClick={handleDeleteFile}
    >
      <CrossIcon width={10} height={10} />
    </div>
  );
};
