import { ChatPushSelector } from '@features/chatPushSelector';
import { NewDeskButton } from '@features/newDeskButton';
import { NewTaskButton } from '@features/newTaskButton';
import { RedirectToTgButton } from '@features/redirectToTgButton';

export const Header = () => {
  return (
    <header className='flex gap-[19px] bg-transparent p-[16px_17px_0]'>
      <NewDeskButton />
      <NewTaskButton />
      <RedirectToTgButton />
      <ChatPushSelector />
    </header>
  );
};
