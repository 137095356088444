import { Fragment } from 'react/jsx-runtime';
import { SettingsListProps } from '../types';

const shortSeparator = (
  <div className='h-[1px] w-[calc(100%_-_60px)] self-end bg-[var(--tg-theme-secondary-bg-color)]' />
);

const longSeparator = (
  <div className='h-[1px] w-full bg-[var(--tg-theme-secondary-bg-color)]' />
);

export const SettingsList = ({ elems, separatorType }: SettingsListProps) => {
  return (
    <ul className='flex w-full flex-col overflow-hidden rounded-normal bg-[var(--tg-theme-section-bg-color)]'>
      {elems.map((e, i) => (
        <Fragment key={i}>
          {e}
          {i !== elems.length - 1 && separatorType === 'long'
            ? longSeparator
            : shortSeparator}
        </Fragment>
      ))}
    </ul>
  );
};
