import React from 'react';

import { Store } from '@store/store';
import { Logic } from '@logic/logic';
import { contextNotProvidedError } from '@hooks/commonHooks';

export interface AppContext {
  store: Store;

  logic: Logic;
}

export const AppContext = React.createContext<AppContext>(
  contextNotProvidedError as never,
);
