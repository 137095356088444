import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTaskModalContext } from '@pages/main/lib/context';
import { getQueryParams } from '@utils/getQueryParams';

export const TaskLoader = () => {
  const { getTask } = useTaskModalContext();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const { chatId, taskId } = getQueryParams(
      window.Telegram.WebApp.initDataUnsafe?.start_param ??
        searchParams.get('chatId'),
    );

    if (chatId && taskId) {
      const loadTask = async () => {
        getTask(+chatId, taskId);
      };

      loadTask();
    }
  }, []);

  return null;
};
