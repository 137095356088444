import { ITask, IUser } from '@@types/types';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { useStore } from '@hooks/storeHook';
import { CloseDrawerIcon } from '@pages/icons/closeDrawer';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IAssigneSelector {
  onHide: () => void;
  task: ITask;
  setTask: (field: string, value: ITask[keyof ITask]) => void;
}

export const AssigneSelector = observer(
  ({ onHide, setTask, task }: IAssigneSelector) => {
    const [filter, setFilter] = useState('');
    const { chatInfo } = useStore();
    const { t } = useTranslation();
    const users = chatInfo?.users;

    const onChangeUser = (user: IUser) => {
      onHide();
      if (user) {
        const newUsers = isUserAsigned(user)
          ? task.responsible.filter((t) => t.id !== user.id)
          : [...task.responsible, user];
        setTask('responsible', newUsers);
      } 
      extraLightHapticFeedback();
    };

    const isUserAsigned = (user: IUser) =>
      task.responsible.some((e) => e.id === user.id);

    return (
      <>
        <div className='mb-[5px] mt-[15px] flex max-h-[48px] min-h-[48px] w-full flex-row items-center rounded-[12px] bg-[#F5F5F5] px-[16px] py-[10px]'>
          <input
            value={filter}
            placeholder={t('input.namePlaceholder')}
            onChange={(e) => setFilter(e.target.value)}
            className='rounded-full mr-[10px] w-full appearance-none border-none bg-transparent text-[17px] text-grey-text shadow-none outline-none'
          />
          <div onClick={() => setFilter('')} className='cursor-pointer'>
            <CloseDrawerIcon size={24} />
          </div>
        </div>
        {users
          ?.filter((item) =>
            filter.length > 0
              ? item?.first_name
                  ?.toLowerCase()
                  .includes(filter.toLowerCase()) ||
                item?.last_name?.toLowerCase().includes(filter.toLowerCase())
              : item,
          )
          .map((user) => {
            const lastName = user.last_name ?? '';
            const firstName = user.first_name ?? '';
            const userName = lastName + ' ' + firstName;
            const initials =
              (lastName[0]?.toUpperCase() ?? '') +
              (firstName[0]?.toUpperCase() ?? '');
            return (
              <div
                onClick={() => onChangeUser(user)}
                className='flex w-full items-center gap-[7px] border-0 border-b-[1px] border-solid border-border-grey py-[10px]'
                key={user.id}
              >
                {user.photo_url ? (
                  <img
                    src={user.photo_url}
                    className='h-[32px] w-[32px] rounded-[100px] bg-grey object-contain'
                  />
                ) : (
                  <div className='flex h-[32px] w-[32px] items-center justify-center rounded-[100px] bg-blue text-base font-bold text-white'>
                    {initials}
                  </div>
                )}
                <span className='text-large font-medium'>{userName}</span>
                <span
                  className={classNames(
                    'ml-auto cursor-pointer text-large text-blue',
                    {
                      'text-red': isUserAsigned(user),
                    },
                  )}
                >
                  {isUserAsigned(user)
                    ? t('button.delete')
                    : t('button.assign')}
                </span>
              </div>
            );
          })}
      </>
    );
  },
);
