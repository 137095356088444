import { ITask, IUser } from '@@types/types';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { useTelegram } from '@hooks/useTelegram';
import { ArrowRight } from '@pages/icons/arrowRight';
import { CloseDrawerIcon } from '@pages/icons/closeDrawer';
import { AssigneSelector } from '@ui/EntitySelectors/AssigneSeletor/AssigneSelector';
import { MiniModal } from '@ui/MiniModal/MiniModal';

import { useEffect, useRef, useState } from 'react';
import Drawer from 'react-bottom-drawer';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';
export const AssignedSelectButton = ({
  setTask,
  task,
  users,
}: {
  setTask: (field: string, value: ITask[keyof ITask]) => void;
  task: ITask;
  users?: IUser[];
}) => {
  const isTg = useTelegram();
  const [drawerIsOpen, setDrawer] = useState(false);
  const [filter, setFilter] = useState('');
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);
  const [parentElement, setParentElement] = useState<Element | null>(null);
  useEffect(() => {
    setParentElement(ref.current);
  }, []);

  const tg = window.Telegram.WebApp;

  const onChangeUser = (user: IUser) => {
    onCloseDrawer();
    if (user) {
      const newUsers = isUserAsigned(user)
        ? task.responsible.filter((t) => t.id !== user.id)
        : [...task.responsible, user];
      setTask('responsible', newUsers);
    }
    extraLightHapticFeedback();
  };

  const onOpenDrawer = () => {
    tg.MainButton.hide();

    setTimeout(() => {
      extraLightHapticFeedback();
      setDrawer(true);
    }, 200);
  };

  const onCloseDrawer = () => {
    setDrawer(false);
    extraLightHapticFeedback();

    setTimeout(() => {
      tg.MainButton.show();
    }, 200);
  };

  const isUserAsigned = (user: IUser) =>
    task.responsible.some((e) => e.id === user.id);

  return (
    <>
      <div
        onClick={onOpenDrawer}
        className='flex h-[46px] w-full cursor-pointer items-center gap-[15px] border-0 border-b-[1px] border-solid border-border-grey'
        ref={ref}
      >
        <AssignIcon />
        <span className='text-base vsm:text-large'>{t('performer')}</span>

        <div className='ml-auto flex items-center gap-[16px]'>
          {task.responsible.length === 1 && (
            <>
              {task.responsible[0].photo_url ? (
                <img
                  src={task.responsible[0].photo_url}
                  className='h-[24px] w-[24px] rounded-[100px] bg-grey object-contain'
                />
              ) : (
                <div className='inline-flex min-h-[24px] min-w-[24px] items-center justify-center rounded-[100px] bg-blue align-middle text-small font-bold text-white'>
                  {(task.responsible[0].last_name?.[0]?.toUpperCase() ?? '') +
                    (task.responsible[0].first_name?.[0]?.toUpperCase() ?? '')}
                </div>
              )}
              <span className='text-base text-grey-text vsm:text-large'>
                {task.responsible[0].last_name
                  ? task.responsible[0].last_name + '...'
                  : task.responsible[0].first_name}
              </span>
            </>
          )}
          {task.responsible.length > 1 && (
            <div className='flex gap-3'>
              {task.responsible.map((r) => {
                return r.photo_url ? (
                  <img
                    src={r.photo_url}
                    className='inline h-[21px] w-[21px] rounded-[100px] bg-grey object-contain align-middle'
                  />
                ) : (
                  <div className='inline-flex min-h-[21px] min-w-[21px] items-center justify-center rounded-[100px] bg-blue align-middle text-small font-bold text-white'>
                    {(r.last_name?.[0]?.toUpperCase() ?? '') +
                      (r.first_name?.[0]?.toUpperCase() ?? '')}
                  </div>
                );
              })}
            </div>
          )}
          <ArrowRight />
        </div>
      </div>

      {parentElement && !isTg && (
        <AnimatePresence mode='wait'>
          {drawerIsOpen && (
            <MiniModal
              parent={parentElement}
              direction='right'
              headerTitle={t('performer')}
              onHide={onCloseDrawer}
              body={
                <AssigneSelector
                  onHide={onCloseDrawer}
                  setTask={setTask}
                  task={task}
                />
              }
              classname='w-[390px]'
            />
          )}
        </AnimatePresence>
      )}

      {isTg &&
        ReactDOM.createPortal(
          <Drawer
            onClose={onCloseDrawer}
            className='drawer'
            isVisible={drawerIsOpen}
          >
            <div className='flex max-h-[70vh] min-h-[70vh] flex-col px-[6px] pb-[40px]'>
              <div
                onClick={onCloseDrawer}
                className='absolute right-[15px] top-[15px]'
              >
                <CloseDrawerIcon />
              </div>
              <div className='flex w-full flex-col'>
                <span className='w-full text-center text-large font-bold'>
                  {t('performer')}
                </span>
                <div className='mb-[5px] mt-[15px] flex max-h-[48px] min-h-[48px] w-full flex-row items-center rounded-[12px] bg-[#F5F5F5] px-[16px] py-[10px]'>
                  <input
                    value={filter}
                    placeholder={t('input.namePlaceholder')}
                    onChange={(e) => setFilter(e.target.value)}
                    className='rounded-full mr-[10px] w-full cursor-pointer appearance-none border-none bg-transparent text-[17px] text-grey-text shadow-none outline-none'
                  />
                  <div onClick={setFilter.bind(null, '')}>
                    <CloseDrawerIcon size={24} />
                  </div>
                </div>
                {users
                  ?.filter((item) =>
                    filter.length > 0
                      ? item?.first_name
                          ?.toLowerCase()
                          .includes(filter.toLowerCase()) ||
                        item?.last_name
                          ?.toLowerCase()
                          .includes(filter.toLowerCase())
                      : item,
                  )
                  .map((user) => {
                    const lastName = user.last_name ?? '';
                    const firstName = user.first_name ?? '';
                    const userName = lastName + ' ' + firstName;
                    const initials =
                      (lastName[0]?.toUpperCase() ?? '') +
                      (firstName[0]?.toUpperCase() ?? '');
                    return (
                      <div
                        onClick={() => onChangeUser(user)}
                        className='flex w-full items-center gap-[5px] border-0 border-b-[1px] border-solid border-border-grey py-[10px]'
                        key={user.id}
                      >
                        {user.photo_url ? (
                          <img
                            src={user.photo_url}
                            className='h-[32px] w-[32px] rounded-[100px] bg-grey object-contain'
                          />
                        ) : (
                          <div className='flex h-[32px] w-[32px] items-center justify-center rounded-[100px] bg-blue text-base font-bold text-white'>
                            {initials}
                          </div>
                        )}
                        <span className='text-large font-medium'>
                          {userName}
                        </span>
                        <span
                          className={classNames(
                            'ml-auto cursor-pointer text-large text-blue',
                            {
                              'text-red': isUserAsigned(user),
                            },
                          )}
                        >
                          {isUserAsigned(user)
                            ? t('button.delete')
                            : t('button.assign')}
                        </span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Drawer>,
          document.body,
        )}
    </>
  );
};

const AssignIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.99823 5.12654C6.66349 5.12654 5.54705 6.23894 5.54705 7.65409C5.54705 9.06924 6.66349 10.1816 7.99823 10.1816C9.33297 10.1816 10.4494 9.06924 10.4494 7.65409C10.4494 6.23894 9.33297 5.12654 7.99823 5.12654ZM3.74705 7.65409C3.74705 5.28327 5.63136 3.32654 7.99823 3.32654C10.3651 3.32654 12.2494 5.28327 12.2494 7.65409C12.2494 10.0249 10.3651 11.9816 7.99823 11.9816C5.63136 11.9816 3.74705 10.0249 3.74705 7.65409ZM16.2474 7.98146C15.4815 7.98146 14.8243 8.62333 14.8243 9.46056C14.8243 10.2978 15.4815 10.9397 16.2474 10.9397C17.0132 10.9397 17.6704 10.2978 17.6704 9.46056C17.6704 8.62333 17.0132 7.98146 16.2474 7.98146ZM13.0243 9.46056C13.0243 7.66991 14.4472 6.18146 16.2474 6.18146C18.0475 6.18146 19.4704 7.66991 19.4704 9.46056C19.4704 11.2512 18.0475 12.7397 16.2474 12.7397C14.4472 12.7397 13.0243 11.2512 13.0243 9.46056ZM3.75995 17.4363C2.96153 18.1733 2.65967 19.0374 2.61049 19.5404C2.56212 20.035 2.12188 20.3969 1.62719 20.3485C1.13249 20.3001 0.770666 19.8599 0.819033 19.3652C0.90949 18.44 1.40354 17.1619 2.53903 16.1137C3.69433 15.0472 5.45812 14.2683 7.99823 14.2683C10.5383 14.2683 12.3021 15.0472 13.4574 16.1137C14.5929 17.1619 15.087 18.44 15.1774 19.3652C15.2258 19.8599 14.864 20.3001 14.3693 20.3485C13.8746 20.3969 13.4343 20.0351 13.386 19.5404C13.3368 19.0374 13.0349 18.1733 12.2365 17.4363C11.4579 16.7176 10.1498 16.0683 7.99823 16.0683C5.84668 16.0683 4.53856 16.7176 3.75995 17.4363ZM15.1038 15.1683C15.1038 14.6713 15.5068 14.2683 16.0038 14.2683C18.5439 14.2683 20.3077 15.0472 21.463 16.1137C22.5985 17.1619 23.0926 18.44 23.183 19.3652C23.2314 19.8599 22.8696 20.3001 22.3749 20.3485C21.8802 20.3969 21.4399 20.0351 21.3916 19.5404C21.3424 19.0374 21.0405 18.1733 20.2421 17.4363C19.4635 16.7176 18.1554 16.0683 16.0038 16.0683C15.5068 16.0683 15.1038 15.6654 15.1038 15.1683Z'
      fill='#007AFF'
    />
  </svg>
);
