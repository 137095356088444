import { useState, useRef, useEffect } from 'react';
import { Icon } from './Icon';
import { motion } from 'framer-motion';
import { darkTheme } from '@hooks/useTelegram';
import { appColors } from '@config/theme';
import { SharingBar } from '@ui/SharingBar/SharingBar';

export const ShareBotButton: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const buttonRef = useRef<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsExpanded((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  return (
    <motion.div
      ref={buttonRef}
      className={`fixed bottom-[35px] right-[15px] flex cursor-pointer items-center justify-center rounded-[50%] px-[1px] text-white`}
      style={{ height: '70px' }}
      onClick={handleClick}
      initial={{ width: '70px', borderRadius: '50%' }}
      animate={{
        width: isExpanded ? 'calc(100% - 30px)' : '70px',
        height: isExpanded ? '95px' : '70px',
        borderRadius: isExpanded ? '10px' : '50%',
        backgroundColor: !isExpanded
          ? appColors.green
          : darkTheme
            ? appColors.darkCardBackground
            : appColors.greySecondary,
      }}
      transition={{ duration: 0.2 }}
    >
      {isExpanded && <SharingBar />}
      <motion.div
        initial={{ opacity: 1 }}
        animate={{
          opacity: isExpanded ? 0 : 1,
          display: isExpanded ? 'none' : 'block',
        }}
        transition={{ duration: 0 }}
      >
        <Icon />
      </motion.div>
    </motion.div>
  );
};
