import { darkTheme, useTelegram } from '@hooks/useTelegram';
import clx from 'classnames';
import { useState } from 'react';
import { ContactCardProps } from '../types';
import { useLogic } from '@hooks/storeHook';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { AppRoutesPaths, navigateTo } from '@config/navigation';
import { getFullName } from '@shared/lib/stringUtils';
import { Avatar } from '@shared/ui/avatar';

export const ContactCard = ({ contact }: ContactCardProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const isTg = useTelegram();
  const logic = useLogic();

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const onClickHandler = async () => {
    await logic.createChat(contact.id);
    if (!isTg) {
      return;
    }
    extraLightHapticFeedback();
    navigateTo(AppRoutesPaths.Boards);
  };

  return (
    <li
      className={clx(
        `relative flex cursor-pointer items-center bg-transparent px-[10px] py-[5px] transition-colors duration-300`,
        { 'min-h-16': isTg },
        { 'bg-[#191919]': darkTheme && !isHovered },
        { 'bg-white': !darkTheme && !isHovered },
        { '!bg-darkCardBackground': darkTheme && isHovered },
        { '!bg-greyHover': !darkTheme && isHovered },
      )}
      onClick={onClickHandler}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {contact.photo_url ? (
        <Avatar
          width={isTg ? 40 : 32}
          height={isTg ? 40 : 32}
          src={contact.photo_url}
          className='mr-[10px]'
        />
      ) : (
        <Avatar
          width={isTg ? 40 : 32}
          height={isTg ? 40 : 32}
          string={contact.first_name[0].toUpperCase()}
          className='mr-[10px]'
        />
      )}
      <p className='max-w-[80%] overflow-hidden overflow-ellipsis whitespace-nowrap'>
        {getFullName(contact.first_name, contact.last_name)}
      </p>
    </li>
  );
};
