import { AxiosError } from 'axios';

export class VisibleError extends Error {
  readonly message: string;

  readonly innerError?: Error | Partial<AxiosError>;

  constructor(
    errorOrMessage: string | Error,
    innerError?: Error | Partial<AxiosError> | unknown,
  ) {
    const message =
      typeof errorOrMessage === 'string'
        ? errorOrMessage
        : errorOrMessage instanceof VisibleError
          ? errorOrMessage.message
          : 'Что-то пошло не так.';

    super(message);

    this.message = message;
    this.innerError =
      innerError ??
      (errorOrMessage instanceof Error ? errorOrMessage : undefined);
  }
}
