import { TimeInputProps } from './types';

export const TimeInput = (props: TimeInputProps) => {
  return (
    <input
      {...props}
      type='time'
      className={`${props.className} bg-white text-black text-center rounded-[8px] border border-solid border-[#D1D1D6] text-[14px] p-[4px_8px]`}
    />
  );
};
