export const russianSpeakingCountries = [
  'RU',
  'BY',
  'KZ',
  'UA',
  'KG',
  'MD',
  'TJ',
  'TM',
  'UZ',
];
