import { AppRoutesPaths } from '@config/navigation';
import { createContext, useContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

export enum SettingSectionsEnum {
  MAIN = 'MAIN',
  SETTINGS = 'SETTINGS',
  LANGUAGE = 'LANGUAGE',
}

interface NavigationContextType {
  stack: SettingSectionsEnum[];
  currentSection: SettingSectionsEnum;
  goBack: () => void;
  navigateTo: (section: SettingSectionsEnum) => void;
}

const NavigationContext = createContext<NavigationContextType | undefined>(
  undefined,
);

export const NavigationProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  const [stack, setStack] = useState<SettingSectionsEnum[]>([
    SettingSectionsEnum.MAIN,
  ]);
  const [currentSection, setCurrentSection] = useState<SettingSectionsEnum>(
    SettingSectionsEnum.MAIN,
  );

  const navigateTo = (section: SettingSectionsEnum) => {
    setStack((prevStack) => [...prevStack, section]);
    setCurrentSection(section);
  };

  const goBack = () => {
    if (stack.length > 1) {
      setStack((prevStack) => prevStack.slice(0, -1));
      setCurrentSection(stack[stack.length - 2]);
    } else if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(AppRoutesPaths.Boards);
    }
  };

  return (
    <NavigationContext.Provider
      value={{ stack, currentSection, goBack, navigateTo }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useSettingsNavigation = (): NavigationContextType => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error(
      'useSettingsNavigation must be used within a NavigationProvider',
    );
  }
  return context;
};
