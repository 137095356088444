import { appColors } from '@config/theme';

export const SelectedIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.17435 16C5.70054 16 5.2978 15.7902 4.96613 15.3706L0.364242 9.55074C0.233944 9.39263 0.139182 9.23755 0.0799556 9.08552C0.0266519 8.93349 0 8.77537 0 8.61117C0 8.24629 0.118453 7.94527 0.355358 7.7081C0.592264 7.47092 0.891357 7.35234 1.25264 7.35234C1.6613 7.35234 2.00481 7.53174 2.28318 7.89054L6.13881 12.9076L13.6635 0.665906C13.8175 0.422653 13.9774 0.252376 14.1433 0.155074C14.3091 0.0516914 14.5164 0 14.7651 0C15.1264 0 15.4225 0.115545 15.6535 0.346636C15.8845 0.577727 16 0.872672 16 1.23147C16 1.37742 15.9763 1.52338 15.9289 1.66933C15.8815 1.81528 15.8075 1.96731 15.7068 2.12543L7.39145 15.3341C7.10716 15.778 6.70146 16 6.17435 16Z'
        fill={appColors.blue}
      />
    </svg>
  );
};
