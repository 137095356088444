import { ITask } from '@@types/types';
import { ArrowRight } from '@pages/icons/arrowRight';
import { CalendarIcon } from '@pages/icons/calendarIcon';
// import { CloseDrawerIcon } from '@components/icons/closeDrawer';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { useTelegram } from '@hooks/useTelegram';
import { DateCircle } from '@ui/DateCircle/DateCircle';
import { DateSelector } from '@ui/EntitySelectors/DateSelector/DateSelector';
import { MiniModal } from '@ui/MiniModal/MiniModal';
import { calcateDeadline } from '@utils/calculateDeadline';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePickerDrawer } from './DatePickerDrawer';
import { RepeatTypesEnum } from '@ui/Calendar/types';
import { AnimatePresence } from 'framer-motion';
export const DateSelectButton = ({
  setTask,
  task,
}: {
  setTask: (field: ITask) => void;
  task: ITask;
}) => {
  const isTg = useTelegram();
  const ref = useRef<HTMLDivElement>(null);
  const [parentElement, setParentElement] = useState<Element | null>(null);
  const { t } = useTranslation();

  const calculatedDeadline = calcateDeadline(task.deadline);
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);

  const tg = window.Telegram?.WebApp;

  useEffect(() => {
    setParentElement(ref.current);
  }, []);

  const onOpenDrawer = () => {
    tg.MainButton.hide();

    setTimeout(() => {
      setIsDatePickerVisible(true);
      extraLightHapticFeedback();
    }, 200);
  };

  const switchRepeatValueFromType = (type: RepeatTypesEnum, date: Date) => {
    if (!date) return 'never';
    switch (type) {
      case RepeatTypesEnum.EVERY_YEAR:
        return moment(date).format('MM-DD');
      case RepeatTypesEnum.EVERY_MONTH:
        return moment(date).format('D');
      case RepeatTypesEnum.EVERY_WEEKDAY:
        return String(date.getDay());
      case RepeatTypesEnum.EVERYDAY:
        return '1';
      default:
        return 'never';
    }
  };

  const onCloseDrawer = () => {
    setIsDatePickerVisible(false);
    if (!isTg) return;

    extraLightHapticFeedback();
    setTimeout(() => {
      tg.MainButton.show();
    }, 200);
  };

  const onSaveDateHandler = (
    date: Date | null,
    priority?: true,
    repeatType: RepeatTypesEnum = RepeatTypesEnum.NEVER,
  ) => {
    if (date)
      setTask({
        ...task,
        deadline: date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null,
        priority: priority ? 1 : 0,
        repeat: {
          type: repeatType,
          value: switchRepeatValueFromType(repeatType, date),
          time: moment(date).format('HH:mm:ss'),
        },
      });
    setIsDatePickerVisible(false);
    if (!isTg) return;

    extraLightHapticFeedback();
    setTimeout(() => {
      tg.MainButton.show();
    }, 200);
  };

  return (
    <>
      <div
        onClick={onOpenDrawer}
        className='relative flex h-[46px] w-full cursor-pointer items-center gap-[15px] border-0 border-b-[1px] border-solid border-border-grey'
        ref={ref}
      >
        <CalendarIcon />
        <span className='text-base vsm:text-large'>{t('date')}</span>
        <div className='ml-auto flex items-center gap-[16px]'>
          {calculatedDeadline !== null && <DateCircle task={task} />}
          {
            <span className='text-base text-grey-text vsm:text-large'>
              {task.deadline
                ? moment(task.deadline).format('DD MMMM')
                : t('button.select')}
            </span>
          }
          <ArrowRight />
        </div>
      </div>

      {parentElement && !isTg && (
        <AnimatePresence mode='wait'>
          {isDatePickerVisible && (
            <MiniModal
              parent={parentElement}
              direction='right'
              headerTitle={t('dueDate')}
              onHide={onCloseDrawer}
              body={<DateSelector task={task} onSave={onSaveDateHandler} />}
              classname='w-[390px]'
            />
          )}
        </AnimatePresence>
      )}

      {isTg && (
        <DatePickerDrawer
          isVisible={isDatePickerVisible}
          task={task}
          onSaveDateHandler={onSaveDateHandler}
          onCloseDrawer={onCloseDrawer}
        />
      )}
    </>
  );
};
