import { LanguageSwitcher } from './LanguageSwitcher';

export const Footer = () => {
  return (
    <footer className='footer'>
      <div className='wrapper footer__wrapper'>
        <a href='#' className='logo'>
          <img src='assets/fav-icon.svg' alt='' />
          BOARDS
        </a>

        <p>2024</p>

        <LanguageSwitcher />
      </div>
    </footer>
  );
};
