import { darkTheme, useTelegram } from '@hooks/useTelegram';
import { PlusIcon } from '@pages/icons/plusIcon';
import { Button, ButtonViews } from '@shared/ui/button';
import { useTranslation } from 'react-i18next';
import { NewTaskButtonProps } from '../types';
import { Icon } from './Icon';
import { useTaskModalContext } from '@pages/main/lib/context';

export const NewTaskButton = ({ insideDesk, deskId }: NewTaskButtonProps) => {
  const isTg = useTelegram();
  const ctx = useTaskModalContext();
  const { t } = useTranslation();

  const onAddTaskHandler = () => {
    ctx.newTaskOpen(deskId)
  };

  if (insideDesk) {
    return (
      <button
        onClick={onAddTaskHandler}
        className={`flex h-fit w-fit cursor-pointer border-none bg-transparent p-0 ${darkTheme ? 'text-white' : 'text-black'}`}
      >
        <PlusIcon />
      </button>
    );
  }

  return isTg ? (
    <button
      onClick={onAddTaskHandler}
      className='absolute bottom-[35px] right-[35px] cursor-pointer border-none bg-transparent'
    >
      <Icon />
    </button>
  ) : (
    <Button
      view={ButtonViews.FILLED}
      icon={<PlusIcon />}
      onClick={onAddTaskHandler}
      className='!pr-[10px]'
    >
      {t('task')}
    </Button>
  );
};
