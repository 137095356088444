import { ITask } from '@@types/types';
import FlameIcon from '@assets/icons/flame.png';
import { CalendarIcon } from '@pages/icons/calendarIcon';
import { CloseDrawerIcon } from '@pages/icons/closeDrawer';
import { MoreActionsIcon } from '@pages/icons/moreActionsIcon';
import { CalendarComponent } from '@ui/Calendar/Calendar';
import { RepeatTypesEnum } from '@ui/Calendar/types';
import {
  calculateNextWeekLastDay,
  calculateThisWeekLastDay,
} from '@utils/calculateDeadline';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import Drawer from 'react-bottom-drawer';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

interface IDatePickerDrawer {
  isVisible: boolean;
  task: ITask;
  onSaveDateHandler: (
    date: Date | null,
    priority?: true,
    repeat?: RepeatTypesEnum,
  ) => void;
  onCloseDrawer: () => void;
}

export const DatePickerDrawer: FC<IDatePickerDrawer> = ({
  isVisible,
  task,
  onSaveDateHandler,
  onCloseDrawer,
}) => {
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isVisible) setIsShowCalendar(false);
  }, [isVisible]);

  return ReactDOM.createPortal(
    <Drawer
      onClose={() => onCloseDrawer()}
      className={`${isShowCalendar ? 'drawer-white' : 'drawer-grey'}`}
      isVisible={isVisible}
    >
      <div
        onClick={() => onCloseDrawer()}
        className='absolute right-[15px] top-[15px] cursor-pointer'
      >
        <CloseDrawerIcon />
      </div>
      <div className='flex w-full flex-col items-center'>
        <div className='mb-[10px] flex w-full items-center justify-center px-[15px]'>
          <span className='text-large font-medium !tracking-[-0.4px]'>
            {t('dueDate')}
          </span>
        </div>

        {!isShowCalendar && (
          <div className='mb-[40px] mt-[20px] flex w-full flex-col rounded-normal bg-white py-[2px]'>
            <div
              className='flex cursor-pointer items-center gap-4 py-[12px] pl-4'
              onClick={() => onSaveDateHandler(new Date(), true)}
            >
              <div className='flex min-h-[21px] min-w-[21px] items-center justify-center'>
                <img src={FlameIcon} width={14} height={18} />
              </div>
              <p className='text-lg m-0 font-normal'>
                {t('dateType.urgently')}
              </p>
            </div>
            <div className='w-[calc(100%_-_60px)] self-end border-0 border-t-[0.5px] border-solid border-[#d9d9d9]' />
            {(!(moment().day() === 5 && moment().hour() >= 19) ||
              moment().day() < 5) && (
              <>
                <div
                  className='flex cursor-pointer items-center gap-4 py-[12px] pl-4'
                  onClick={() => onSaveDateHandler(calculateThisWeekLastDay())}
                >
                  <div className='flex min-h-[21px] min-w-[21px] items-center justify-center rounded-[100px] bg-green'>
                    <span className='text-[13px] text-white'>I</span>
                  </div>
                  <p className='text-lg m-0 font-normal'>
                    {t('dateType.thisWeek')}
                  </p>
                </div>
                <div className='w-[calc(100%_-_60px)] self-end border-0 border-t-[0.5px] border-solid border-[#d9d9d9]' />
              </>
            )}
            <div
              className='flex cursor-pointer items-center gap-4 py-[12px] pl-4'
              onClick={() => onSaveDateHandler(calculateNextWeekLastDay())}
            >
              <div className='flex min-h-[21px] min-w-[21px] items-center justify-center rounded-[100px] bg-blue'>
                <span className='text-[13px] text-white'>II</span>
              </div>
              <p className='text-lg m-0 font-normal'>
                {t('dateType.nextWeek')}
              </p>
            </div>
            <div className='w-[calc(100%_-_60px)] self-end border-0 border-t-[0.5px] border-solid border-[#d9d9d9]' />
            <div
              className='flex cursor-pointer items-center gap-4 py-[12px] pl-4'
              onClick={() => setIsShowCalendar(true)}
            >
              <div className='relative -left-[2px] h-[23px] w-[21px]'>
                <CalendarIcon width={21} height={23} fill='#A2ACB0' />
              </div>
              <p className='text-lg m-0 font-normal'>
                {t('dateType.selectDate')}
              </p>
            </div>
            <div className='w-[calc(100%_-_60px)] self-end border-0 border-t-[0.5px] border-solid border-[#d9d9d9]' />
            <div
              className='flex cursor-pointer items-center gap-4 py-[12px] pl-4'
              onClick={() => onSaveDateHandler(null)}
            >
              <div className='h-[21px] w-[21px] rotate-90'>
                <MoreActionsIcon width={21} height={21} />
              </div>
              <p className='text-lg m-0 font-normal'>{t('dateType.noDate')}</p>
            </div>
          </div>
        )}

        {isShowCalendar && (
          <CalendarComponent
            defaultRepeatType={task.repeat?.type}
            onSave={onSaveDateHandler}
            defaultValue={task.deadline && moment(task.deadline).format('')}
          />
        )}
      </div>
    </Drawer>,
    document.body,
  );
};
