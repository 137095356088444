import { LoadState } from '@@types/loadState';
import { Store } from '@store/store';
import { observable } from 'mobx';
import { actionAsync } from 'mobx-utils';
import { ApiLogic } from './apiLogic';

import { IChat, IChatInfo, IDesk, ITask } from '@@types/types';

export class Logic {
  @observable
  private store: Store;

  private api: ApiLogic;

  constructor(store: Store) {
    this.store = store;

    this.api = new ApiLogic('');
  }

  initializeApiLogic(initData: string) {
    this.api = new ApiLogic(initData);
  }

  private handleError() {
    const errorMessage =
      this.store.locale === 'en'
        ? 'Something went wrong. The data may not have been saved.'
        : 'Что-то пошло не так. Возможно, данные не сохранились.';
    alert(errorMessage);
  }

  @actionAsync
  updateTask = async (task: ITask) => {
    try {
      const response = await this.api.updateTask(task);
      this.store.tasks = response;
    } catch {
      this.handleError();
    }
  };

  @actionAsync
  deleteTask = async (taskId: number) => {
    if (!this.store.chat_id) return;

    try {
      const response = await this.api.deleteTask(this.store.chat_id, taskId);
      this.store.tasks = response;
      this.getAllTasksAndChats();
    } catch {
      this.handleError();
    }
  };

  @actionAsync
  getTask = async (chatId: number, taskId: number) => {
    try {
      const response = await this.api.getTask(chatId, taskId);

      this.store.selectedTask = response;
    } catch (e) {
      this.store.selectedTask = null;
    }
  };

  @actionAsync
  createBoard = async (name: string, parentId?: number) => {
    if (!this.store.chat_id) return;

    try {
      this.store.loading = LoadState.Loading;
      const response = await this.api.createBoard(
        name,
        this.store.chat_id,
        parentId,
      );
      this.store.chatInfo = response;

      const infoResponse = await this.api.getTasks(this.store.chat_id);
      this.store.tasks = infoResponse;

      this.store.loading = LoadState.Success;
    } catch {
      this.handleError();
      this.store.loading = LoadState.Error;
    }
  };

  @actionAsync
  createChat = async (userId: number) => {
    this.store.loading = LoadState.Loading;
    try {
      const chatInfoResponse = await this.api.createChat(userId);

      this.store.chat_id = +chatInfoResponse.id;
      await this.switchProject();

      this.store.loading = LoadState.Success;
    } catch (e) {
      this.handleError();
      this.store.loading = LoadState.Error;
    }
  };

  @actionAsync
  updateChat = async (chat: IChat) => {
    try {
      this.store.updateChat(chat);
      await this.api.updateChat(chat);
    } catch {
      this.handleError();
      this.store.rootLoading === LoadState.Error;
    }
  };

  @actionAsync
  updateChatInfo = async (chat: IChatInfo) => {
    try {
      this.store.updateChatInfo(chat);
      await this.api.updateChat(chat);
    } catch {
      this.handleError();
      this.store.rootLoading === LoadState.Error;
    }
  };

  @actionAsync
  updateBoards = (task: ITask, desk_id: number, position: number) => {
    if (!this.store.chat_id) return;

    try {
      this.api.updateBoards(task.id!, desk_id, position, this.store.chat_id);
      this.store.updateTask({ ...task, desk_id: desk_id });
    } catch {
      this.handleError();
      this.store.rootLoading === LoadState.Error;
    }
  };

  @actionAsync
  loadChatInfo = async (chatId: number) => {
    try {
      const infoResponse = await this.api.getChatInfo(chatId);
      this.store.setChatInfo(infoResponse);
      return infoResponse;
    } catch {
      this.handleError();
    }
  };

  @actionAsync
  loadJointChats = async () => {
    try {
      const contacts = await this.api.getJointChats();
      this.store.contacts = contacts.filter((e) => e.id !== this.store.user_id);
    } catch {
      this.handleError();
    }
  };

  @actionAsync
  sendMessage = async (taskId: number, formData: FormData) => {
    if (!this.store.chat_id) return;

    try {
      const res = await this.api.sendMessage(
        this.store.chat_id,
        taskId,
        formData,
      );
      this.store.addMessageToSelectedTask(res);
    } catch {
      this.handleError();
    }
  };

  @actionAsync
  loadInitialData = async () => {
    this.store.rootLoading = LoadState.Loading;
    if (this.store.chat_id === undefined) return;

    try {
      if (this.store.chat_id !== 0) {
        await Promise.all([
          this.api
            .getTasks(this.store.chat_id)
            .then((data) => (this.store.tasks = data)),
          this.loadChatInfo(this.store.chat_id).then((data) =>
            this.store.setUser(
              data!.users.find((u) => u.id === this.store.user_id)!,
            ),
          ),
          this.getAllTasksAndChats(),
          this.loadJointChats(),
        ]);
      } else {
        await Promise.all([this.getAllTasksAndChats(), this.loadJointChats()]);
      }
      this.store.rootLoading = LoadState.Success;
    } catch (e) {
      this.store.rootLoading = LoadState.Error;
      this.store.tasks = {};
    }
  };

  @actionAsync
  switchProject = async () => {
    this.store.loading = LoadState.Loading;
    if (!this.store.chat_id) return;
    try {
      await Promise.all([
        this.api
          .getTasks(this.store.chat_id)
          .then((data) => (this.store.tasks = data)),
        this.loadChatInfo(this.store.chat_id),
        this.getAllTasksAndChats(),
      ]);

      this.store.loading = LoadState.Success;
    } catch (e) {
      this.store.loading = LoadState.Error;
      this.store.tasks = {};
    }
  };

  @actionAsync
  getAllTasksAndChats = async () => {
    if (this.store.chat_id === undefined) return;

    const allTaskResponce = await this.api.getAllTasks(this.store.chat_id);
    const allChatsResponce = await this.api.getAllChats(this.store.chat_id);
    this.store.allTasks = allTaskResponce;
    this.store.setChats(allChatsResponce);
  };

  @actionAsync
  deleteBoard = async (desk_id: number, transferDeskId?: number) => {
    this.store.loading = LoadState.Loading;
    if (!this.store.chat_id) return;

    try {
      const res = await this.api.deleteBoard(
        desk_id,
        this.store.chat_id,
        transferDeskId,
      );
      const infoResponse = await this.api.getChatInfo(this.store.chat_id);
      this.store.chatInfo = infoResponse;
      this.store.tasks = res;
      this.store.loading = LoadState.Success;
    } catch (e) {
      this.store.loading = LoadState.Error;
    }
  };

  @actionAsync
  updateBoard = async (board: IDesk) => {
    if (!this.store.chat_id) return;

    try {
      await this.api.updateBoard(board, this.store.chat_id);
    } catch {
      this.handleError();
    }
  };

  @actionAsync
  updateDeskList = async (query: string) => {
    if (!this.store.chat_id) return;

    try {
      await this.api.updateDeskList(query, this.store.chat_id);
    } catch {
      this.handleError();
    }
  };

  @actionAsync
  createTask = async (formData: FormData) => {
    if (!this.store.chat_id) return;

    try {
      const response = await this.api.createTask(this.store.chat_id, formData);

      this.store.tasks = response.tasks;
    } catch {
      this.handleError();
    }
  };

  @actionAsync
  addFileToTask = async (taskId: number, file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    if (!this.store.chat_id) return;

    try {
      const response = await this.api.addFileToTask(
        this.store.chat_id,
        taskId,
        formData,
      );
      this.store.addFileToSelectedTask(response);
      this.getAllTasksAndChats();
    } catch {
      this.handleError();
    }
  };
}
