import { useLogic, useStore } from '@hooks/storeHook';
import { Input } from '@shared/ui/input';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { prepareDataForApi } from '../lib/prepareDataForApi';
import { AttachFileButton } from './AttachFileButton';
import { DocumentPreview } from './DocumentPreview';
import { ImagePreview } from './ImagePreview';
import { VideoPreview } from './VideoPreview';

export const CommentInput = () => {
  const { selectedTask } = useStore();
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [documents, setDocuments] = useState<File[]>([]);
  const { t } = useTranslation();
  const logic = useLogic();

  const handleSendComment = async () => {
    const messageObj = {
      text: comment,
      parent_id: null,
      type: 0,
    };
    const formData = prepareDataForApi(messageObj, [...files, ...documents]);

    setIsLoading(true);
    await logic.sendMessage(selectedTask!.id!, formData);
    setIsLoading(false);
    setComment('');
    setFiles([]);
    setDocuments([]);
  };

  const onPasteHandler = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const items = e.clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.type.startsWith('image/')) {
        if (files.length >= 5) {
          alert(t('alert.maxAttachmentsCount'));
          return;
        }

        const file = item.getAsFile();
        if (file) {
          setFiles((prevImages) => [...prevImages, file]);
        }
        e.preventDefault();
      }
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    const isDocumentUpload = selectedFiles.every(
      (file) =>
        !file.type.startsWith('image/') && !file.type.startsWith('video/'),
    );

    if (
      (isDocumentUpload && files.length > 0) ||
      (!isDocumentUpload && documents.length > 0)
    ) {
      alert(t('alert.docsWithPhotosError'));
      return;
    }

    if (isDocumentUpload) {
      if (documents.length + selectedFiles.length > 5) {
        alert(t('alert.docCount'));
        return;
      }

      selectedFiles.forEach((file) => {
        if (file.size > 40 * 1024 * 1024) {
          alert(t('alert.maxFileSize'));
        } else {
          setDocuments((prevDocs) => [...prevDocs, file]);
        }
      });
    } else {
      if (files.length + selectedFiles.length > 5) {
        alert(t('alert.maxVideoPhotoCount'));
        return;
      }

      selectedFiles.forEach((file) => {
        if (file.size > 40 * 1024 * 1024) {
          alert(t('alert.maxFileSize'));
        } else {
          setFiles((prevImages) => [...prevImages, file]);
        }
      });
    }
  };

  const onChangeHandler = (str: string) => {
    setComment(str);
  };

  const onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;
    e.preventDefault();
    handleSendComment();
  };

  const handleDeleteFile = (idx: number) =>
    setFiles((prev) => prev.filter((_, i) => i !== idx));

  const handleDeleteDocument = (idx: number) =>
    setDocuments((prev) => prev.filter((_, i) => i !== idx));

  const preIconTemplate = (
    <AttachFileButton handleFileChange={handleFileChange} />
  );

  return (
    <div className='w-full'>
      {(files.length > 0 || documents.length > 0) && (
        <div className='flex max-w-full gap-4 overflow-auto rounded-t-normal border border-b-0 border-solid border-[#D1D1D6] bg-white p-2 sm:p-4'>
          {files.map((file, index) => {
            const isImage = file.type.startsWith('image/');
            return isImage ? (
              <ImagePreview
                file={file}
                key={index}
                handleDeleteFile={() => handleDeleteFile(index)}
                deletable={!isLoading}
              />
            ) : (
              <VideoPreview
                file={file}
                key={index}
                handleDeleteFile={() => handleDeleteFile(index)}
                deletable={!isLoading}
              />
            );
          })}
          {documents.length > 0 &&
            documents.map((doc, index) => (
              <DocumentPreview
                file={doc}
                key={index}
                handleDeleteFile={() => handleDeleteDocument(index)}
                deletable={!isLoading}
              />
            ))}
        </div>
      )}
      <Input
        value={comment}
        onKeyDown={onPressEnter}
        onChange={(e) => onChangeHandler(e.target.value)}
        placeholder={t('input.commentPlaceholder')}
        className={classNames('mb-2 text-large sm:mb-0', {
          '!rounded-t-[0]': files.length > 0 || documents.length > 0,
        })}
        showSendButton={
          !!comment.length || !!files.length || !!documents.length
        }
        onSend={handleSendComment}
        loading={isLoading}
        onPaste={onPasteHandler}
        preIcon={preIconTemplate}
      />
    </div>
  );
};
