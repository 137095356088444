import { useStore } from '@hooks/storeHook';
import { getFullName } from '@shared/lib/stringUtils';
import { Avatar } from '@shared/ui/avatar';

export const UserInfo = () => {
  const { user } = useStore();
  if (!user) return <></>;

  return (
    <>
      {user.photo_url ? (
        <Avatar
          width={104}
          height={104}
          src={user.photo_url}
          className='mt-[9px]'
        />
      ) : (
        <Avatar
          width={104}
          height={104}
          string={getFullName(user.first_name, user.last_name)}
          className='mt-[25px]'
        />
      )}
      <h1 className='mt-4 text-[28px] font-medium'>{user.first_name}</h1>
      <h2 className='mt-[5px] text-biggerPlus font-normal text-[var(--tg-theme-hint-color)]'>
        @{user.username}
      </h2>
    </>
  );
};
