import { darkTheme } from '@hooks/useTelegram';
import { FC, Fragment, useState } from 'react';
import clx from 'classnames';

export interface ITabElement {
  mainIcon: JSX.Element | string;
  title: string;
  onClick: () => void;
  iconHeight: number;
  iconWidth: number;
  subtitle?: string;
}

interface ITabList {
  elements: ITabElement[];
}

export const TabList: FC<ITabList> = ({ elements }) => {
  return (
    <ul
      className={`${!darkTheme ? 'bg-white text-black' : 'bg-darkCardBackground text-white'} flex w-full flex-col overflow-hidden rounded-[8px] px-0`}
    >
      {elements.map((e, i) => (
        <Fragment key={i}>
          <TabElem e={e} />
          {i !== elements.length - 1 && (
            <div
              className={`self-end border-0 border-t-[0.5px] border-solid ${darkTheme ? 'border-darkCardBackground' : 'border-[#d9d9d9]'}`}
              style={{ width: `calc(100% - ${e.iconWidth + 16 + 16}px)` }}
            />
          )}
        </Fragment>
      ))}
    </ul>
  );
};

const TabElem = ({ e }: { e: ITabElement }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <li
      className={clx(
        'flex cursor-pointer items-center gap-4 px-4 py-[7px] transition-colors duration-300',
        { 'bg-[#191919]': darkTheme && !isHovered },
        { 'bg-white': !darkTheme && !isHovered },
        { '!bg-darkCardBackground': darkTheme && isHovered },
        { '!bg-greyHover': !darkTheme && isHovered },
      )}
      onClick={e.onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {typeof e.mainIcon === 'string' ? (
        <img
          src={e.mainIcon}
          className='rounded-[8px]'
          style={{ width: e.iconWidth, height: e.iconHeight }}
        />
      ) : (
        <div style={{ width: e.iconWidth, height: e.iconHeight }}>
          {e.mainIcon}
        </div>
      )}
      <div className='flex h-full w-full flex-col justify-center gap-[2px] overflow-hidden'>
        <p className='m-0 overflow-hidden text-ellipsis whitespace-nowrap p-0 text-[17px]'>
          {e.title}
        </p>
        {e.subtitle && (
          <p className='m-0 p-0 text-[15px] text-grey-text first-letter:uppercase'>
            {e.subtitle}
          </p>
        )}
      </div>
    </li>
  );
};
