import { darkTheme } from '@hooks/useTelegram';
import { motion } from 'framer-motion';
import { FC, Fragment, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

interface ISettingButton {
  title: string;
  callback: () => void;
  textColor?: string;
}

interface ISettingPopup {
  refElement: HTMLElement;
  onHide: () => void;
  buttons: ISettingButton[];
}

export const SettingsPopup: FC<ISettingPopup> = ({
  refElement,
  onHide,
  buttons,
}) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const rect = refElement.getBoundingClientRect();
    setPosition({
      top: rect.top + window.scrollY + 35,
      left: rect.left + window.scrollX  - 238,
    });

    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onHide();
      }
    };

    const timer = setTimeout(() => {
      document.addEventListener('click', handleClickOutside);
    }, 0);

    return () => {
      clearTimeout(timer);
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onHide]);

  return ReactDOM.createPortal(
    <motion.div
      ref={popupRef}
      className={`absolute z-[99] flex min-w-[255px] flex-col rounded-[12px] bg-white text-[15px] drop-shadow-[-5px_5px_20px_rgba(0,0,0,0.18)] ${darkTheme && '!bg-[#191919]'} overflow-hidden`}
      initial={{ opacity: 0, translateY: -20 }}
      animate={{ opacity: 1, translateY: 0 }}
      exit={{ opacity: 0, translateY: -20 }}
      transition={{ duration: 0.08 }}
      style={{
        top: `${position.top}px`, // Позиционируем на основе вычисленной позиции
        left: `${position.left}px`, // Позиционируем на основе вычисленной позиции
      }}
    >
      {buttons.map((e, i) => (
        <Fragment key={i}>
          <p
            className={`m-0 cursor-pointer p-0 px-[16px] py-[13px] ${darkTheme ? 'text-white hover:bg-darkCardBackground' : 'hover:bg-taskInfoBackground'}`}
            onClick={e.callback}
            style={{ color: e.textColor }}
          >
            {e.title}
          </p>
          {i !== buttons.length - 1 && (
            <div className='w-[calc(100%_-_16px)] self-end border-0 border-t-[0.5px] border-solid border-[#d9d9d9]' />
          )}
        </Fragment>
      ))}
    </motion.div>,
    document.body,
  );
};
