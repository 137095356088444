import { DeskTypes, ITask } from '@@types/types';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { AppRoutesPaths } from '@config/navigation';
import { appColors } from '@config/theme';
import { useLogic, useStore } from '@hooks/storeHook';
import { useTelegram } from '@hooks/useTelegram';
import { CompleteIcon } from '@pages/icons/completeIcon';
import { DeleteIcon } from '@pages/icons/deleteIcon';
import { goBack } from '@shared/lib/nav';
import { Button } from '@ui/Button/Button';
import { Switcher } from '@ui/Switcher/Switcher';
import { ITaskModal } from '@ui/TaskModal/TaskModal';
import { TaskCommentTab } from '@widgets/taskCommentTab';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AssignedSelectButton } from './AssignedSelectButton';
import { DateSelectButton } from './DateSelectButton';
import { SelectBoardButton } from './SelectBoardButton';
import { SettingSection } from './SettingSection';
import { TaskAndSubtasksComponent } from './TaskAndSubTasksComponent';

interface ITaskPage extends Pick<ITaskModal, 'onHide'> {}

export const TaskPage: FC<ITaskPage> = observer(({ onHide }) => {
  const isTg = useTelegram();

  const logic = useLogic();
  const location = useLocation();
  const { t } = useTranslation();

  const { selectedTask, chatInfo } = useStore();

  const [taskInfo, setTaskInfo] = useState(selectedTask);
  const [activeIndex, setActiveIndex] = useState(0);

  const sliderElements = [t('tasks'), t('comments')];

  if (!selectedTask || !taskInfo) {
    return null;
  }

  const tg = window.Telegram?.WebApp;

  useEffect(() => {
    if (!isTg) return;

    tg.MainButton.text = t('button.save');
    tg.MainButton.color = appColors.blue;

    const timeout = setTimeout(() => {
      tg.MainButton.show();
      tg.MainButton.onClick(onSave);
    }, 200);

    return () => {
      tg.MainButton.offClick(onSave);
      clearTimeout(timeout);
    };
  }, [taskInfo]);

  useEffect(() => {
    if (!isTg) return;

    extraLightHapticFeedback();
    if (activeIndex === 0) tg.MainButton.show();
    else tg.MainButton.hide();
  }, [activeIndex]);

  useEffect(() => {
    if (!isTg) return;

    tg.isClosingConfirmationEnabled = true;

    tg.BackButton.show();
    tg.setHeaderColor('#efeff4');

    tg.BackButton.offClick(goBack);
    tg.BackButton.onClick(onHide);

    return () => {
      tg.isClosingConfirmationEnabled = false;

      tg.BackButton.onClick(goBack);

      if (location.pathname === AppRoutesPaths.My) tg.BackButton.hide();

      tg.BackButton.offClick(onHide);
      if (tg.colorScheme === 'dark') {
        tg.setHeaderColor('#2C2C2E');
      } else {
        tg.setHeaderColor('#F9F9F9');
      }
    };
  }, []);

  const setTask = (field: string, value: ITask[keyof ITask]) => {
    const newObj = { ...taskInfo };
    if (field in taskInfo) {
      // @ts-ignore
      newObj[field] = value;
    }
    setTaskInfo(newObj);
  };

  //ПЕРЕДЕЛАТЬ! КОСТЫЛЬ ДЛЯ ВЫБОРА ДАТЫ (функция setTask не может сразу обновить 2 поля)
  const setTaskForDate = (value: ITask) => {
    setTaskInfo(value);
  };

  const onSave = async () => {
    tg.MainButton.showProgress();

    if (taskInfo.id)
      await logic.updateTask({ ...taskInfo, desk_id: taskInfo.desk?.id });
    else {
      const formData = new FormData();
      formData.append('title', taskInfo.title ?? '');
      if (taskInfo.deadline) {
        formData.append('deadline', taskInfo.deadline);
        formData.append('repeat[type]', taskInfo.repeat.type);
        formData.append('repeat[value]', taskInfo.repeat.value);
        formData.append('repeat[time]', taskInfo.repeat.time);
      }
      formData.append('desk_id', String(taskInfo.desk!.id));
      taskInfo.responsible.forEach((r, i) => {
        taskInfo.responsible[0] &&
          formData.append(`responsible[${i}][id]`, String(r.id));
      });
      taskInfo.subtasks.forEach((e, i) => {
        formData.append(`subtasks[${i}][title]`, e.title);
        formData.append(`subtasks[${i}][status]`, e.status);
      });
      taskInfo.newFiles!.forEach((e, i) => {
        formData.append(`files[${i}]`, e.blob);
      });
      await logic.createTask(formData);
    }

    tg.HapticFeedback.notificationOccurred('success');
    tg.MainButton.hideProgress();
    tg.MainButton.hide();
    onHide();
  };

  const onUpdateTaskStatus = async () => {
    const completeDesk = chatInfo?.desks.find(
      (item) => item.type === DeskTypes['DONE'],
    );
    if (completeDesk) {
      await logic.updateTask({
        ...selectedTask,
        desk: completeDesk,
        desk_id: completeDesk.id,
      });
      extraLightHapticFeedback();

      onHide();
    }
  };

  const onDeleteTask = async () => {
    await logic.deleteTask(taskInfo.id!);
    extraLightHapticFeedback();
    onHide();
  };

  return (
    <div
      className={`flex h-screen w-full ${isTg ? 'bg-taskInfoBackground' : 'overflow-y-hidden bg-white'} gap-[25px]`}
    >
      <div
        className={`${isTg || (!isTg && !selectedTask.id) ? 'w-full' : 'min-w-[356px]'} relative flex h-full max-h-screen flex-col gap-[25px] overflow-x-hidden overflow-y-hidden pb-[15px]`}
      >
        {isTg && selectedTask.id && (
          <div className='fixed left-0 right-0 top-0 z-[2] w-full p-[16px] backdrop-blur-sm'>
            <Switcher
              elements={sliderElements}
              onClick={(index) => setActiveIndex(index)}
              activeIndex={activeIndex}
            />
          </div>
        )}

        <motion.div
          className='flex h-full w-full gap-[20px] transition-transform duration-200 ease-in-out'
          style={{
            translateX:
              activeIndex === 0
                ? '0%'
                : activeIndex === 1
                  ? 'calc(-100% - 20px)'
                  : 'calc(-200% - 35px)',
          }}
        >
          <section
            className={`flex h-full min-w-full flex-col gap-[25px] ${isTg && selectedTask.id ? 'pt-[64px]' : 'px-0 pt-6'} ${isTg && 'px-[15px]'} overflow-y-auto`}
          >
            <SettingSection name={t('taskTitle')}>
              <TaskAndSubtasksComponent setTask={setTask} task={taskInfo} />
            </SettingSection>

            <SettingSection name={t('settings')}>
              <DateSelectButton task={taskInfo} setTask={setTaskForDate} />
              <AssignedSelectButton
                users={chatInfo?.users}
                task={taskInfo}
                setTask={setTask}
              />
              {chatInfo?.desks && (
                <SelectBoardButton task={taskInfo} setTask={setTask} />
              )}
            </SettingSection>

            {taskInfo.id && (
              <SettingSection name={t('actions')}>
                <div
                  onClick={onUpdateTaskStatus}
                  className='flex w-full cursor-pointer items-center gap-[16px]'
                >
                  <CompleteIcon />
                  <div className='flex h-[46px] w-full items-center border-0 border-b-[1px] border-solid border-border-grey'>
                    <span className='text-large text-green'>
                      {t('button.completeTask')}
                    </span>
                  </div>
                </div>
                <div
                  onClick={onDeleteTask}
                  className='flex w-full cursor-pointer items-center gap-[16px]'
                >
                  <DeleteIcon />
                  <div className='flex h-[46px] w-full items-center'>
                    <span className='text-large text-red'>
                      {t('button.delete')}
                    </span>
                  </div>
                </div>
              </SettingSection>
            )}

            {!isTg && (
              <Button
                classname='mx-[18px] mt-auto sm:h-[50px]'
                onClick={onSave}
                disabled={!taskInfo.desk}
              >
                {t('button.save')}
              </Button>
            )}
          </section>

          {isTg && selectedTask.id && (
            <section className='flex h-full min-w-full flex-col gap-[25px] px-[15px] pt-[64px]'>
              <TaskCommentTab />
            </section>
          )}
        </motion.div>
      </div>
      {!isTg && selectedTask.id && (
        <div className='backdrop-blur-smr relative z-[2] w-full overflow-hidden p-[16px]'>
          <div className='absolute left-0 top-0 z-[2] w-full py-[24px] backdrop-blur-sm'>
            <div className='pl-[15px] text-bigger uppercase text-grey-text'>
              {t('comments')}
            </div>
          </div>
          <section className='flex h-full min-w-full flex-col gap-[25px] overflow-auto pt-[48px]'>
            <TaskCommentTab />
          </section>
        </div>
      )}
    </div>
  );
});
