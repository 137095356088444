import cn from 'classnames';
import styles from './styles.module.css';
import { ButtonProps } from './types';

export const Button = ({ view, icon, children, ...props }: ButtonProps) => {
  return (
    <button
      {...props}
      className={cn(
        'flex h-7 cursor-pointer items-center rounded-[8px] bg-white p-0 px-2 transition-all active:scale-[0.95]',
        styles[view],
        props.className,
      )}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      <p
        className={cn(
          'content-center border-0',
          styles.label,
          icon && '!border-l',
        )}
      >
        {children}
      </p>
    </button>
  );
};
