import { NewTaskButton } from '@features/newTaskButton';
import { useTelegram } from '@hooks/useTelegram';
import { TelegramHeader } from '@pages/HomePage/components/TelegramHeader';
import { goBack } from '@shared/lib/nav';
import { Layout } from '@shared/ui/layouts';
import { BoardHeader } from '@widgets/boardHeader';
import { Menu } from '@widgets/projectMenu';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Board } from './Board';

export const Page = observer(() => {
  const isTg = useTelegram();

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    if (!tg) return;

    tg.BackButton.show();

    tg.BackButton.onClick(goBack);

    return () => {
      tg.BackButton.hide();
      tg.BackButton.offClick(goBack);
    };
  }, []);

  return (
    <Layout>
      {!isTg && <Menu />}
      <motion.div
        className='relative flex max-h-[100svh] w-full flex-col overflow-y-hidden'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
      >
        {isTg ? <TelegramHeader /> : <BoardHeader />}
        <Board />
        {isTg && <NewTaskButton />}
      </motion.div>
    </Layout>
  );
});
