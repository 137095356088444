import { useTranslation } from 'react-i18next';

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'ru' ? 'en' : 'ru';
    i18n.changeLanguage(newLanguage);
    localStorage.setItem('language_code', newLanguage);
  };

  return (
    <div className='switch-wrapper'>
      <div className='switch'>
        <input
          id='language-toggle'
          className='check-toggle check-toggle-round-flat'
          type='checkbox'
          onChange={toggleLanguage}
          checked={i18n.language === 'en'}
        />
        <label htmlFor='language-toggle' />
        <div className='on flex h-full items-center justify-center gap-1'>
          <img src='assets/ru.png' className='flag' alt='Русский язык' />
          <span>РУС</span>
        </div>
        <div className='off flex h-full items-center justify-center gap-1'>
          <img src='assets/uk.png' className='flag' alt='English' />
          <span>EN</span>
        </div>
      </div>
    </div>
  );
};
