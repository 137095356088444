import { getRandomColor } from '@config/theme';
import clx from 'classnames';
import { AvatarProps } from './types';
import { useEffect, useState } from 'react';

export const Avatar = ({
  string,
  src,
  height = 32,
  width = 32,
  className,
}: AvatarProps) => {
  const [backgroundColor, setBackgroundColor] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    if (string) {
      setBackgroundColor(getRandomColor());
    }
  }, [string]);

  if (string)
    return (
      <div
        className={clx(
          'flex items-center justify-center rounded-[50%] text-biggerPlus font-bold text-white',
          className,
        )}
        style={{
          backgroundColor: backgroundColor,
          width: width,
          minWidth: width,
          height: height,
        }}
      >
        {string}
      </div>
    );

  if (src)
    return (
      <img
        className={clx('rounded-[50%]', className)}
        width={width}
        height={height}
        src={src}
      />
    );
};
