import { CommentInput } from '@features/commentInput';
import { useStore } from '@hooks/storeHook';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { Comment } from './Comment';
import { IMessage } from '@@types/types';
import moment from 'moment';

const groupMessagesByDate = (messages: IMessage[]) => {
  const groupedMessages: Record<string, IMessage[]> = {};

  messages.forEach((message) => {
    const date = moment(message.created_at).format('DD MMM');
    if (!groupedMessages[date]) {
      groupedMessages[date] = [];
    }
    groupedMessages[date].push(message);
  });

  return groupedMessages;
};

export const TaskCommentTab = observer(() => {
  const { selectedTask } = useStore();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.scrollTop = ref.current.scrollHeight;
  }, [selectedTask?.messages?.length]);

  const groupedMessages = groupMessagesByDate(selectedTask?.messages || []);

  return (
    <div className='flex h-full flex-col gap-[14px] sm:gap-[18px]'>
      <div
        className='flex h-full flex-col items-center justify-start gap-2 overflow-auto sm:gap-[18px]'
        style={{
          flexGrow: 1,
        }}
        ref={ref}
      >
        {Object.entries(groupedMessages).map(([date, messages]) => (
          <div key={date} className='w-full first:mt-auto'>
            <p className='mb-2 text-center text-base text-grey-text sm:text-bigger'>
              {date}
            </p>
            <div className='flex flex-col gap-[3.5px] sm:gap-[18px]'>
              {messages.map((message) => (
                <Comment key={message.id} message={message} />
              ))}
            </div>
          </div>
        ))}
      </div>
      <CommentInput />
    </div>
  );
});
