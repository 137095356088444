import { useTranslation } from 'react-i18next';

export const Preview = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const videoSrc =
    currentLanguage === 'ru' ? 'assets/IMG_9500.MOV' : 'assets/video-en.MP4';

  return (
    <section className='preview'>
      <div className='wrapper preview__wrapper'>
        <div className='preview__left'>
          <div className='preview__chip'>{t('landing.preview.badge')}</div>
          <h1
            className='preview__title'
            dangerouslySetInnerHTML={{ __html: t('landing.preview.title') }}
          />
          <div
            className='preview__desc'
            dangerouslySetInnerHTML={{ __html: t('landing.preview.offer') }}
          />
          <a
            href='https://t.me/TgBoards_bot'
            target='_blank'
            className='button button--big'
          >
            {t('landing.button.getApp')}
          </a>

          <div className='preview__advantages'>
            <p className='preview__advantages-title'>15 000 +</p>
            <p className='preview__advantages-desc'>
              {t('landing.preview.subtitle')}
            </p>
          </div>
        </div>

        <div className='preview__right'>
          <div className='preview__phone'>
            <img src='assets/preview-phone.png' alt='' />
            <video
              src={videoSrc}
              playsInline
              autoPlay
              loop
              muted
              poster='assets/preview-phone.png'
            />
          </div>
        </div>
      </div>
    </section>
  );
};
