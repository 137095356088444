import { SvgIconProps } from '@shared/types/icon.types';

export const StarIcon = ({ width = 20, height = 20 }: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 13.89L6.24 16.16L7.23 11.88L3.91 9L8.29 8.63L10 4.59L11.71 8.63L16.09 9L12.77 11.88L13.76 16.16M20 7.74L12.81 7.13L10 0.5L7.19 7.13L0 7.74L5.45 12.47L3.82 19.5L10 15.77L16.18 19.5L14.54 12.47L20 7.74Z'
        fill='currentColor'
      />
    </svg>
  );
};
