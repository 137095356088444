import { AttachIcon } from '@pages/icons/attachIcon';
import { Select } from '@shared/ui/select';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { AttachFileButtonProps, FileType, FileTypeEnum } from '../types';

export const AttachFileButton = ({
  handleFileChange,
}: AttachFileButtonProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<FileType | null>(null);

  const { t } = useTranslation();

  const inputOptions: FileType[] = [
    {
      label: t('fileType.img'),
      type: FileTypeEnum.IMG,
    },
    {
      label: t('fileType.video'),
      type: FileTypeEnum.VIDEO,
    },
    {
      label: t('fileType.doc'),
      type: FileTypeEnum.DOC,
    },
  ];

  const handleAttachClick = () => {
    setIsOpen(true);
  };

  const handleChange = (option: SingleValue<FileType>) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const acceptSwitcher = () => {
    if (!selectedOption) return '';
    switch (selectedOption.type) {
      case FileTypeEnum.IMG:
        return 'image/jpeg, image/png, image/gif, image/webp';
      case FileTypeEnum.VIDEO:
        return 'video/*';
      case FileTypeEnum.DOC:
        return '.pdf, .zip, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .csv, .txt';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (selectedOption) {
      fileInputRef.current?.click();
      setSelectedOption(null);
    }
  }, [selectedOption]);

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={menuRef}>
      <AttachIcon onClick={handleAttachClick} />
      <Select
        value={selectedOption}
        options={inputOptions}
        menuIsOpen={isOpen}
        menuPlacement='top'
        onMenuClose={() => setIsOpen(false)}
        onChange={handleChange}
        classNames={{
          control: () => '!hidden',
          menu: () => 'drop-shadow-none',
        }}
        closeMenuOnSelect
      />
      <input
        type='file'
        ref={fileInputRef}
        onChange={handleFileChange}
        multiple
        accept={acceptSwitcher()}
        style={{ display: 'none' }}
      />
    </div>
  );
};
